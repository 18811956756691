*{
  padding: 0;
  margin: 0;
}

.field-group{
  .input-group{
    border-bottom: 1px solid white;
    .input-group-prepend{       
        .input-group-text{
            background-color: transparent;
            border: none;
        }
     }
    .inputField{
        background-color: transparent;
        border: none!important;
        outline: none!important;
        box-shadow: none!important;
        color: white
    }   
  }
}

.popup-form-cus {
  label{
    display: flex;
    flex-direction: column;
  }
}

@keyframes fadeIn {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.spin {
  animation: spin 2s ease;
}

@keyframes spin {
 
  0% {
      transform: rotateY(180deg);
  }    
  
  100% { 
      transform: rotateY(360deg);
  } 
}

