.header{
	width: 100%;
}

.dashboardMainSec {
	width: calc(100% - 85px);
	margin-left: 85px;
	padding: 0 85px;
	display: flex;
	justify-content: space-evenly;
	.dashImgTxt {
		position: relative;
		width: 28%;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		cursor: pointer;
		.dashPlainImg {
			display: block;
		}
		.dashYellImg {
			display: none;
		}
		.dashImgInnerTxt {
			position: absolute;
			font-size: 42px;
			font-weight: 200;
			color: #fff;
			display: flex;
			flex-direction: column;
			text-align: center;
			line-height: 55px;
			text-transform: capitalize;
		}
		&:hover {
			.dashYellImg {
				display: block;
				transform: rotateZ(-45deg);
			}
			.dashPlainImg {
				display: none;
			}
			.dashImgInnerTxt {
				color: #000;
			}
		}
	}
}

@media screen and (max-width:1600px) {
	.dashboardMainSec {
		.dashImgTxt {
			.dashImgInnerTxt {
				margin: 0;
				font-size: 36px;
				line-height: 1.3;
			}
		}
	}
}