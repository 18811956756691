.userListcontainer{
  margin-top: 2em;

  .userListrow {
    display: flex;
    margin: 1.5em 0;
    .userImg {
      width: 240px;
      padding: 0 0.6em;

      .imgCol {
        width: 208px;
        height: 208px;
        background-color: #1d2c33;
        position: relative;
        img {
          position: absolute;
          left: -10px;
          bottom: -10px;
        }
        .user_image {
          width: 200px;
          height: 200px;
          object-fit: cover;
          margin: 14px;
        }
      }
    }
    .userDetail {
      display: flex;
      background-color: #1d2c33;
      width: calc(100% - 240px);
      margin: 1em 0;
      padding: 0 3em;
      .userDetailCol {
        padding-left: 0.2em;
        width: calc(100% / 3);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:first-child {
          padding-left: 0;
        }
        p {
          padding: 0;
          margin: 0;
          color: #d67013;
          font-size: 1.5em;
          margin-bottom: 0.3em;
          font-weight: 500;
        }
        h4 {
          padding: 0;
          margin: 0;
          font-size: 2em;
          width: 100%;
        }
      }
    }
  }
}

// alluser pages
.allUserRow {
  display: flex;
  margin: 1em 0;
  width: 100%;

  .addedUserDetail {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #1d2c33;
    padding: 0.3em 1em;
    width: 60%;
    cursor: pointer;

    .userDetailCol {
      padding-left: 0.2em;
      width: 50%;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:first-child {
        padding-left: 0;
      }
      p {
        padding: 0;
        margin: 0;
        color: #d67013;
        font-size: 1.2em;
        margin-bottom: 0.3em;
        font-weight: 500;
      }
      h4 {
        padding: 0;
        margin: 0;
        font-size: 1.4em;
        width: 100%;
        font-weight: 600;
      }
    }
  }
  // subscribtion col
  .userSubs {
    background-color: #1d2c33;
    width: 12.33%;
    color: white;
    margin-left: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    h4 {
      font-size: 20px;
      font-weight: 400;
    }
  }
  // status col
  .statusCol {
    background-color: #1d2c33;
    width: 12.33%;
    color: white;
    margin-left: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    h4 {
      font-size: 20px;
      font-weight: 400;
    }
  }
  // user action column
  .userActioncol {
    background-color: #1d2c33;
    width: 12.33%;
    color: white;
    margin-left: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .edit {
      button {
        background-color: #d67013;
        color: white !important;
        border: none !important;
        box-shadow: none !important;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 15px !important;
      }
      h6{
        cursor: pointer;
      }
    }
    .Userdelete{
      h6{
        cursor: pointer;
      }
    }
  }
}

// classes for  uaser page subscribtion & status

.not-sent-subs,
.not-view-status {
  color: #e22929;
}
.sent-subs {
  color: #d67013;
}
.call-back-status {
  color: #ffd800;
}
.no-ans-status {
  color: #76eaff;
}

.user_image {
  width: "200px";
  height: "200px";
  object-fit: "cover";
}



// DASHBORAD USER LIST HOVER CLASS
.dashUserlist{
  cursor:all-scroll;
  
  &:hover{
    
    text-decoration: none;
    cursor: pointer;
  }
}