.mainSecbox{
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.mainSecInner{
		position: absolute;
		top: 0;
		left: 0;
		width: 85px;
		border-right: 2px solid #fff;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.projects {
		color: #fff;
		height: calc(100vh - 136px);
		display: flex;
  		  flex-direction: column;
		  padding-left:30px;
		.header {
			width: 100%;
			display: flex;
			align-items: center;			
		}
		.mainsec {
			width: 100%;
			display: flex;
			justify-content: center;
			flex: 1;
			align-items: center;

			a {
				color: #fff;
			}
			.centerimgboc {
				display: flex;	
				align-items: center;
				justify-content: center;
				position: relative;
				&:hover{
					.centerTxtsec{
						color: #000;
					}
					svg{
						transform: rotateZ(-50deg);
						transition: all ease 500ms;
						circle{
							fill: #fff;
						}
						g{
							path:nth-child(3){
								fill: #FDB03C;
							}
							path:nth-child(4){
								fill: #fff;
							}
						}
					}
				}
				svg {
					width: 100%;
					height:50vh;
				}
				.centerTxtsec {
					position: absolute;
					font-weight: 200;
					font-size: 5.4vh;
					line-height: 61px;
					margin: 0;
				}
			}
		}
	}
}
