.userTopRow {
    background-color: #0e1518;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    color: white;
    margin-top: 50px;

    .userCount{
        font-size: 1.8em;
        font-weight: 600;
    }
    .createUser{
        button{
        background-color: #d67013;
        color: white!important;
        border: none!important;
        box-shadow: none!important;
        font-size: 18px;
        font-weight: 600;
        padding: 5px 18px!important;
        }
    }

    .userSearch{
        .form-group{
            margin: 0;
            input{
                box-shadow: none!important;
            }
        }
    }

}