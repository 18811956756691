
.signupDetail{
	display: flex;
	margin-top: 2%;

	.signupDetailSide {
		width: 70%;
		padding-left: 3%;

		.userImgRow {
			.userImgCol {
				display: flex;
				flex-wrap: wrap;
				.userImgBox {
					margin: 10px 20px;
					background: #1d2c33;                    
					img {                     
							transform: translate(5px, 5px);
							width: 200px;
							height: 200px;
							object-fit: cover;
					}
				}
			}
		}

		// signup-detail-row
		.signupDetailRow{
			padding: 50px 0 0 20px;
			display: flex;
			flex-wrap: wrap;

			.info {
				width: 40%;
				.label {
					color: #d66f13;
					font-weight: 600;
				}

				.labelText {
					color: white;
				}
			}
		}
	}
	
	// signup slide
	.signupNotesSide {
		display: flex;
		justify-content: center;       
		width: 30%;

		form {
			width: 300px;
			max-width: 100%; 
			label {
				background-color: #d77013;
				color: white;
				width: 100%;
				text-align: center;
				font-weight: 600 ;
				padding: 0.2em 0;
			}

			textarea {
				background-color: #1d2c33;
				border: none;
				border-radius: 0;
				box-shadow: none;
				color: #fff;
			}

			button {
				border: none!important;                       
				font-size: 14px!important;
				border-radius: 0!important;
				box-shadow: none!important;
				background-color: #d77013;
				width: 100%;
			}

			.accountStatus {
				margin-top: 3%;
				text-align: right;
				h4 {
					color:#d77013;
					font-weight: 700;
					margin: 0;
				}
				p {
					color: white;
					margin: 0;
				}
			}
		}        
	}
}