.headerTopSec {
	padding: 40px 40px 15px 16px;
	.topLeftlabel {
		color: #fff;
		text-align: right;
		font-size: 22px;
		font-weight: 200;
	}
	.topHeadTitle {
		color: #fff;
		font-size: 36px;
		font-weight: 200;
		padding-bottom: 20px;
	}
	.topHeaderbtn {
		width: 100%;
		display: flex; 
		gap: 40px;
		.leftYellowbtnSelected {
			border: 1px solid #fff;
			background-color: #fdb03c;
			color: #000;
			border-radius: 30px;
			font-size: 22px;
			padding: 6px 30px;
			width: 20%;
		}
		.leftwhitebtn {
			border: 1px solid #fff;
			background-color: transparent;
			color: #fff;
			border-radius: 30px;
			font-size: 22px;
			padding: 6px 30px;
			width: 20%;
		}
		.leftYellowbtn {
			border: 1px solid #fff;
			background-color: transparent;
			color: #fff;
			border-radius: 30px;
			font-size: 22px;
			padding: 6px 30px;
			width: 20%;
		}
		.leftwhitebtnSelected {
			border: 1px solid #fff;
			background-color: #fdb03c;
			color: #000;
			border-radius: 30px;
			font-size: 22px;
			padding: 6px 30px;
			width: 20%;
		}
		
	}
}
.adminDetal {
	padding: 40px 40px 15px 16px;
	.btnTileNav {
		display: flex;
		align-items: center;
		gap: 50px;
		.adminTotalTitle {
			font-size: 28px;
			color: #fff;
			margin: 0;
			span {
				color: #fdb03c;
			}
		}
		.adminCreateBtn {
			border: 1px solid #fff;
			background-color: transparent;
			color: #fff;
			border-radius: 30px;
			font-size: 22px;
			padding: 6px 30px;
		}
	}
	.adminDetailbox {
		display: flex;
		align-items: stretch;
		padding-top: 40px;
		gap: 40px;
		.indivualDetail {
			width: 70%;
			display: flex;
			justify-content: space-between;
			border: 1px solid #fff;
			border-radius: 20px;
			padding: 25px 35px;
			.labelTexttitle {
				width: 33%;
				.nameLabel {
					color: #fdb03c;
					font-size: 20px;
					line-height: 24px;
					margin-bottom: 8px;
				}
				.nameText {
					color: #fff;
					font-size: 22px;
					line-height: 32px;
					margin: 0;
				}
			}
		}
		.editDeleteBtn {
			width: 30%;
			display: flex;
			justify-content: space-between;
			.editbtn {
				width: 48%;
				border: 1px solid #fff;
				background-color: transparent;
				border-radius: 20px;
				color: #fdb03c;
				font-size: 22px;
				line-height: 32px;
			}
			.deletebtn {
				width: 48%;
				border: 1px solid #fff;
				background-color: transparent;
				border-radius: 20px;
				color: #fdb03c;
				font-size: 22px;
				line-height: 32px;
			}
		}
	}
}

@media screen and (max-width:1600px) {
	.headerTopSec{
		padding : 30px 14px 10px 14px;
		.topLeftlabel{
			font-size: 18px;
		}
		.topHeadTitle{
			font-size: 30px;
		}
		.topHeaderbtn{
			gap: 20px;
			.leftYellowbtnSelected{
				font-size: 16px;
			}
			.leftwhitebtnSelected{
				font-size: 16px;
			}
			.leftwhitebtn{
				font-size: 16px;
			}
			.leftYellowbtn{
				font-size: 16px;
			}
		}
	}
	.adminDetal{
		padding: 30px 14px 15px 14px;
		.btnTileNav{
			.adminCreateBtn{
				font-size: 16px;
			}
			.adminTotalTitle{
				font-size: 24px;
			}
		}
		.adminDetailbox {
			padding-top: 30px;
			gap: 30px;
			.indivualDetail{
				padding: 20px 20px;
				border-radius: 15px;
				.labelTexttitle{
					.nameLabel{
						font-size: 18px;
						line-height: 1.3;
						margin-bottom: 5px;
					}
					.nameText{
						font-size: 16px;
						line-height: 1.3;
					}
				}
			}
			.editDeleteBtn {
				.editbtn{
					font-size: 18px;
					line-height: 1.3;
					border-radius: 15px;
				}
				.deletebtn{
					font-size: 18px;
					line-height: 1.3;
					border-radius: 15px;
				}
			}
		}
	}
}