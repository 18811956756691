.profilePgSidebar {
	position: absolute;
	border-right: 1px solid #fff;
	width: 350px;
	height: 100vh;
	padding: 50px 30px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		display: none;
	}
	
	.sideImglogo {
		display: flex;
		gap: 28px;
		padding-bottom: 20px;
		.sideimgsvgicon {
			width: 45px;
		}
		.sideimgsvgtxt {
			font-size: 12px;
			line-height: 15px;
			font-weight: 200;
			color: #fff;
			margin: 0;
			padding-top: 3px;
		}
	}

	.sideBarlogoTxt {
		.sidelgtxt {
			font-size: 25px;
			line-height: 30px;
			font-weight: 200;
			color: #fff;
		}
		.sidelgsubtxt {
			line-height: 20px;
			font-weight: 200;
			color: #FDB03C;
			margin: 0;
			font-size: 16px
		}
	}

	.filterDwnBox {
		padding: 0 25px;
		.filtertitle {
			padding: 30px 0 10px ;
			.fliterLabel {
				line-height: 20px;
				font-weight: 200;
				color: #FDB03C;
				font-size: 21px;
			}
		}
		.inputfilter {
			margin-left: 15px;
			width: 90%;
			height: 26px;
			border-radius: 10px;
			text-align: center;
			padding: 2px 10px !important;
		}
		.physicalDetailBox {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 12px;
			.phsyBoxTxt {
				width: 50%;
				font-size: 18px;
				color: #fff;
				line-height: 22px;
			}
			.inputDetailfilter {
				width: 50%;
				height: 22px !important;
				border-radius: 10px;
				padding: 0 25px 0 8px;
			}
		}
	}

	.filtershowing {
		.filtershwtext {
			font-size: 21px;
			color: #fff;
			text-align: center;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			gap: 5px;
			.filtershwtextspan {
				color: #fdb03c;
			}
		}
	}
	
	.dropDown {
		margin-left: 15px;
		width: 90%;
		position: relative;
		.dropDownToggle {
			background: #fff;
			display: flex;
			height: 28px;
			border-radius: 10px;
			text-align: center;
			padding: 0px 10px !important;
			justify-content: center;
			align-items: center;
			width: 100%;
			border: 1px solid;
		}
		.dropDownToggle::after {
			display: inline-block;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.35em solid;
			border-right: 0.35em solid transparent;
			border-bottom: 0;
			border-left: 0.35em solid transparent;
			width: 10px;
			position: absolute;
			right: 9px;
		}
		.multipleCategorySelectedName {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;
			width: 90%;
		}
		.dropDownMenu {
			position: absolute;
			top: 100%;
			left: 15px;
			z-index: 1000;
			display: block;
			float: left;
			min-width: 10rem;
			padding: 0;
			margin: 0.125rem 0 0;
			font-size: 1rem;
			color: #212529;
			text-align: left;
			list-style: none;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 0.25rem;
			width: calc(100% - 25px);
			label {
				width: 100%;
				padding: 0 8px;
				display: inline-flex;
				gap: 5px;
				align-items: center;
				margin-bottom: 0;
				cursor: pointer;
				&:hover {
					background: #3297fd;
					color: #fff;
				}
			}
		}
	}

	.iconMainDiv {
		position: relative;
		width: 100%;
		.iconReset {
			position: absolute;
			height: 24px;
			width: 24px;
			right: -22px;
			top: 0;
			cursor: pointer;
		}
		svg {
			height: 80%;
			width: 80%;
			margin-left: 10px;
		}
	}
}

@media screen and (max-width:1600px) {
	.profilePgSidebar {
		width: 300px;
		padding: 30px;
		.sideImglogo {
			gap:15px;
		}
		.sideBarlogoTxt {
			.sidelgtxt {
				font-size: 22px;
			}
		}
		.filterDwnBox {
			padding: 0px;
			.filtertitle {
				padding: 15px 0 8px;
				.fliterLabel {
					font-size: 19px;
				}
			}
			.inputfilter {
				margin-left: 0px;
				width: 100%;
			}
			.physicalDetailBox {
				.phsyBoxTxt {
					font-size: 16px;
					margin-bottom: 8px;
				}
			}
		}
		.filtershowing {
			.filtershwtext {
				margin: 10px 0 0;
				gap: 0;
			}
		}
		.dropDown {
			width: 100%;
			margin-left: 0;
		}
		.iconMainDiv svg {
			margin-left: 7px;
		}
	}
}