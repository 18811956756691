.rolePgSidebar {
  position: absolute;
  border-right: 1px solid #fff;
  width: 350px;
  height: 100vh;
  padding: 50px 30px;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }

  .noDataMsg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0px auto;
    color: #fff;

    &:hover {
      color: #000;

      .noRoleimg {
        transform: rotate(-50deg);

        circle {
          fill: #fff;
          stroke: #fff;
        }

        g {
          path:nth-child(3) {
            fill: #FDB03C;
          }

          path:nth-child(4) {
            fill: #fff;
          }
        }
      }
    }

    .noRoleimg {
      transition: all ease 500ms;

      circle {
        fill: #0a0f12;
        stroke: #fff;
      }

      g {
        path:nth-child(3) {
          fill: #fff;
        }

        path:nth-child(4) {
          fill: #FDB03C;
        }
      }
    }

    .noRoleText {
      font-size: 24px;
      text-align: center;
      position: absolute;
      margin: 0;
      padding: 0 25px;
    }
  }

  .sideImglogo {
    display: flex;
    gap: 28px;
    padding-bottom: 20px;

    .sideimgsvgicon {
      width: 45px;
    }

    .sideimgsvgtxt {
      font-size: 12px;
      line-height: 15px;
      font-weight: 200;
      color: #fff;
      margin: 0;
      padding-top: 3px;
    }
  }

  .sideBarlogoTxt {
    .sidelgtxt {
      font-size: 25px;
      line-height: 30px;
      font-weight: 200;
      color: #fff;
    }

    .sidelgsubtxt {
      line-height: 20px;
      font-weight: 200;
      color: #FDB03C;
      margin: 0;
      font-size: 16px
    }
  }

  .castTorole {
    height: 100%;

    .rolesLabel {
      font-weight: 200;
      color: #fff;
      font-size: 17px;
      padding: 30px 0;
    }

    .RolesSet {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .roleBoxSet {
        width: 33%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 20px;
        flex-direction: column;
        align-items: center;

        .rolesImg {
          width: 50%;
        }

        .rolesImgYell {
          width: 50%;
          display: none;
        }

        .roleLabelOne {
          color: #56bd82;
          font-size: 17px;
          text-align: center;
          margin: 0;
          padding-top: 5px;
        }

        .roleLabelTwo {
          color: #fff;
          font-size: 17px;
          text-align: center;
        }
      }

      .roleBoxSet:hover {
        cursor: pointer;

        .rolesImg {
          display: none;
        }

        .rolesImgYell {
          display: block;
        }

        .roleLabelOne {
          color: #fff;
        }

        .roleLabelTwo {
          color: #fdb03c;
        }
      }
    }

    .loaderInSidebar {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .InnerLoader {
        width: 60px;
        height: 60px;
        color: #FDB03C !important;
      }
    }
  }

  .rolrShows {
    display: flex;
    flex-direction: column;

    .silebotsubText {
      font-size: 16px;
      color: #fff;
      font-weight: 200;
      line-height: 22px;
      padding-top: 30px;
      text-align: center;
    }

    .arrowcontainer {
      list-style: none;

      .arrowbox {
        text-align: center;
        font-size: 16px;
        font-weight: 200;
        color: #fff;
        line-height: 32px;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 20px;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;

        .dblarrowSvg {
          width: 12px;
          position: absolute;
          left: 18px;
          top: 9px;
        }

        &:hover {
          background-color: #FDB03C;
        }
      }

      .lastarrowbox {
        margin-top: 30px;
        cursor: pointer;
      }
    }

    .disable {
      @extend .arrowbox;
      pointer-events: none;
      opacity: 0.3;
      cursor: default;
    }
  }
}

@media screen and (max-width:1600px) {
  .rolePgSidebar {
    width: 300px;
    padding: 30px;

    .sideImglogo {
      gap: 15px;
    }

    .sideBarlogoTxt {
      .sidelgtxt {
        font-size: 22px;
      }
    }

    .castTorole {
      .rolesLabel{
        padding: 20px 0;
      }
      .RolesSet {
        .roleBoxSet {
          width: 50%;
          .rolesImg {
            max-width: 40px;
            margin-bottom: 10px;
          }
          .rolesImgYell{
            max-width: 40px;
            margin-bottom: 10px;
          }
          .roleLabelOne{
            font-size: 15px;
            padding-top: 0;
          }
          .roleLabelTwo{
            margin-bottom: 5px;
            font-size: 15px;
          }
        }
      }
    }
  }
}