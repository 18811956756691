.contentWrapper{

    .topRow{
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        h4{
            font-size: 0.8em;
            color: rgb(255, 255, 255);
            padding: 0;
            margin:0;

        }

    }

        .contentRow{
            display: flex;         
        .userDatacol{
            width: 75%;
            
        }
        .userAnalyticscol{
            width: 25%;
            color: white;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-top: 5em;
             .UserAnalytics{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 3em;
                span{  
                    &:first-child{
                        margin-bottom: 0.3em;
                    }                  
                    &:nth-child(2) {
                        border-radius: 50%;
                        background-color: rgb(29, 44, 51);
                        width: 158px;
                        height: 158px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 3.5em;
                        font-weight: bold;
                    }
                }
             }

                

        }

        }

    

}