.header{
	width: 100%;
}
.dashboardMainSec {
    width: calc(100% - 85px);
    margin-left: 85px;
    padding: 0 85px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    gap: 45px;
	.dashinnerMainsec {
		cursor: pointer;
		position: relative;
		width: 17%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding-bottom: 20px;
		.dashinnImgTxt{
			position: absolute;
			.dashinnImgTxtOne {
				color: #fff;
				font-size: 20px;
				text-align: center;
			}
			.episodeName {
				display: none;
			}
			.dashinnImgTxtwo {
				color: #FDB03C;
				font-size: 16px;
				text-align: center;
				margin: 0;
			}
		}
		.dashImgDeactive {
			display: block;
		}
		.dashImgactive {
			display: none;
		}
		.dashBotBtn {
			width: 100%;
			position: absolute;
			bottom: -20px;
			justify-content: center;
			gap: 15px;
			padding-top: 20px;
			display: none;
			.dashBotBtntext {
				width: 30%;
				text-align: center;
				color: #fff;
				font-size: 14px;
				line-height: 14px;
				padding: 5px 20px;
				border: 1px solid #fff;
				border-radius: 20px;
				&:hover{
					color: #FFF;
					border: 1px solid #FFF;
					background: #FDB03C;
				  }
			}
		}
		&:hover {
			.dashinnImgTxt {
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				height: 30%;
				width: 80%;
				.dashinnImgTxtOne {
					color: #000;
					margin-bottom: 0;
				}
				.dashinnImgTxtwo {
					color: #000;
					margin-top: 20px;
				}
			}
			.dashImgDeactive {
				display: none;
			}
			.dashImgactive {
				display: block;
				transform: rotate(-50deg);
			}
			.dashBotBtn {
				display: flex;
			}
			.episodeName {
				width: 90%;
				text-align: center;
				font-size: 20px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
				margin: 0 auto;
			}
			.dashinnImgTxtOne{
				font-size: 16px;
			}
		}
	}
	.noDatabox {
		display: flex;
		width: 100%;
		.noDataMsg {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 25%;
			margin: 0px auto;
			color: #fff;
			&:hover {
				color: #000;
				.Noroleimg {
					transform: rotate(-50deg);
					circle {
						fill: #fff;
						stroke: #fff;
					}
					g {
						path:nth-child(3) {
						fill: #FDB03C;
						}
						path:nth-child(4) {
						fill: #fff;
						}
					}
				}
			}
			.Noroleimg {
				transition: all ease 500ms;
					circle {
						fill: #0a0f12;
						stroke: #fff;
					}
					g {
						path:nth-child(3) {
							fill: #fff;
						}
						path:nth-child(4) {
							fill: #FDB03C;
						}
					}
				}
			.noRoleText {
				font-size: 32px;
				text-align: center;
				position: absolute;
				margin: 0;
				padding: 0 10px;
				cursor: pointer;
			}
		}
	}
}

.leftRightHands {
	width: calc(100% - 85px);
	margin-left: 85px;
	display: flex;
	justify-content: center;
	position: relative;
	cursor: pointer;
	.leftHandArrow {
		position: absolute;
		left: 0;
		top: 30px;
		display: flex;
		align-items: center;
		gap: 22px;
		margin-left: 85px;
		&:hover {
			.lefthandtext {
				color: #FDB03C;
			}
			.lefthanfSvg {
				path {
					fill: #FDB03C;
				}
			}
		}
		.lefthandtext {
			font-size: 16px;
			font-weight: 200;
			line-height: 20px;
			color: #fff;
			margin: 0;
		}
		.lefthanfSvg {
			width: 32px;
		}
	}
	.rightHandarrow {
		position: absolute;
		right: 0;
		top: 30px;
		padding-right: 85px;
		display: flex;
		align-items: center;
		gap: 22px;
		&:hover {
			.Righthandtext {
				color: #FDB03C;
			}
			.lefthanfSvg {
				path {
					fill: #fdb03c;
				}
			}
		}
		.Righthandtext {
			color: #fff;
			font-size: 16px;
			font-weight: 200;
			line-height: 20px;
			margin: 0;
		}
		.lefthanfSvg {
			width: 32px;
			path {
				fill: #ffffff;
			}
		}
	}
	.rightDisable {
		@extend .rightHandarrow;
		visibility: hidden;
	}
	.leftDisable {
		@extend .leftHandArrow;
		visibility: hidden;
	}
}

@media screen and (max-width:1600px) {
	.dashboardMainSec {
		padding: 0 30px;
		gap: 30px;
		.dashinnerMainsec {
			padding-bottom:0;
			width: 18%;
			.dashinnImgTxt{
				.dashinnImgTxtOne {
					margin: 0;
				}
				.dashinnImgTxtwo {
					margin-top: 10px;
				}
			}
			.dashBotBtn{
				gap: 10px;
				bottom: -40px;
				.dashBotBtntext{
					padding: 5px 12px;
					width: 70px;
				}
			}
			&:hover{
				.dashinnImgTxt{
					height: auto;
					.dashinnImgTxtwo {
						margin-top: 10px;
					}
				}
			}
		}
		.noDatabox {
			.noDataMsg {
				.noRoleText {
					font-size: 26px;
					width: 100%;
					padding: 0 40px;
				}
			}
		}
	}
}