.collapseSidebar {
  @extend .sceneDetailContainer;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% - 85px) !important;
  margin-left: 85px !important;

  .modelIndivBox {
    width: 12vw !important;
  }
}

.sceneDetailContainer {
  width: calc(100% - 350px);
  margin-left: 350px;
  padding-left: 85px;
  .content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 104px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .headerBox {
      width: 100%;
      display: flex;
      align-items: flex-start;
      padding: 40px 85px 0 0;
      justify-content: space-between;
      position: sticky;
      left: 0;
      .firsthead {
        display: flex;
        gap: 20px;
        .logotoptext {
          font-weight: 200 !important;
          font-size: 38px;
          line-height: 120%;
          color: #fff;
        }
        .logobotText {
          font-weight: 200;
          font-size: 32px;
          line-height: 3.65vh;
          color: #FDB03C;
          padding-bottom: 31px;
          margin: 0;
          span {
            color: #FDB03C;
          }
        }
        .sideImgbox {
          padding-right: 20px;
          padding-top: 5px;
          .sideimgsvgicon {
            width: 50px;
          }
          .sideimgsvgtxt {
            font-size: 14px;
            line-height: 15px;
            font-weight: 200;
            color: #fff;
            margin: 0;
            padding-top: 5px;
          }
        }
      }
      .midHeader {
        .midToptxt {
          font-weight: 200;
          font-size: 36px;
          line-height: 40px;
          color: #fff;
          text-align: center;
        }
        .midbotDiv {
          display: flex;
          justify-content: space-around;
          gap: 30px;
          .midbotinnText {
            color: #fff;
            font-size: 28px;
            font-weight: 200;
            span {
              color: #FDB03C;
            }
          }
        }
      }
      .lastSecHead {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        .lstHeadTopbtn {
          width: 100%;
          border: 1px solid #fff;
          padding: 5px;
          color: #fff;
          font-size: 16px;
          line-height: 16px;
          border-radius: 20px;
          text-align: center;
          margin-bottom: 10px;
          margin-top: 15px;
          cursor: pointer;
          &:hover{
            color: #FFF;
            border: 1px solid #FFF;
            background: #FDB03C;
          }
        }
        .disable {
          @extend .lstHeadTopbtn;
          pointer-events: none;
          opacity: 0.3;
          cursor: default;
        }
        .lstInnerhead {
          width: 100%;
          display: flex;
          gap: 15px;
          .lstHeadBtmbtn {
            width: 50%;
            border: 1px solid #fff;
            padding: 5px;
            color: #fff;
            font-size: 16px;
            line-height: 16px;
            border-radius: 20px;
            text-align: center;
            cursor: pointer;
            &:hover{
              color: #FFF;
              border: 1px solid #FFF;
              background: #FDB03C;
            }
          }
        }
      }
    }

    .innerCastMainContainer {
      display: flex;
      gap: 70px;
      align-items: stretch;
      padding-right: 50px;
      margin: auto;
      .innerCastMainSec {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .iconTextLines {
          width: 100%;
          display: flex;
          gap: 20px;
          align-items: center;
          justify-content: center;
          padding: 35px 0 20px;
          flex-wrap: wrap;
          position: relative;
          .logolinetext {
            background-color: #0a0f12;
            display: flex;
            z-index: 5;
            gap: 15px;
            padding: 0 15px;
            .casticonsvg {
              width: 45px;
              &:hover{
                path{
                  fill: #FDB03C !important;
                }
              }
            }
            .casticonsvgText {
              color: #56db82;
              font-size: 25px;
              margin: 0;
              cursor: pointer;
              &:hover{
                color: #FDB03C
              }
            }
          }
          .lineBorder {
            width: 100%;
            position: absolute;
            height: 1px;
            background-color: #fff;
          }
        }
        .modelImageBox {
          width: auto;
          display: flex;
          gap: 40px;
          justify-content: center;
          .modelIndivBox {
            overflow: hidden;
            width: 12vw;
            position: relative;
            cursor: pointer;
            flex-shrink: 0;
            display: flex;
            height: 42vh;
            &:hover {
              .modelSing {
                transform: translateY(0);
              }
            }
            .SinglModeImg {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 15px;
              object-position: top;
            }
            .modelSing {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              padding: 10px 20px;
              transform: translateY(87px);
              transition: all ease 500ms;
              .ancherbtnsbox {
                .anchrCastbtn {
                  width: 100%;
                  margin-top: 10px;
                  background-color: #fff;
                  color: #000;
                  border-radius: 5px;
                  padding: 2px;
                  text-align: center;
                  background-color: #ff3e3f;
                  color: #FFF;
                  &:hover{
                    color: #FFF;
                    border: 1px solid #FFF;
                    background: #FDB03C;
                  }
                }
                .anchrRolebtn {
                  width: 100%;
                  background-color: #ff3e3f;
                  color: #000;
                  padding: 2px;
                  border-radius: 5px;
                  text-align: center;
                  margin: 0;
                  color: #FFF;
                  &:hover{
                    color: #FFF;
                    border: 1px solid #FFF;
                    background: #FDB03C;
                  }
                }
              }
              .modelName {
                font-size: 36px;
                color: #fff;
                font-weight: 200;
                text-align: center;
                line-height: 120%;
                margin-bottom: 10px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
              .modelLabel {
                color: #fff;
                font-size: 17px;
                font-weight: 200;
                text-align: center;
                margin-bottom: 10px;
              }
              .modelOptionNo {
                color: #fff;
                font-size: 21px;
                text-align: center;
                margin: 0;
                span {
                  color: #d49535;
                }
              }
              .modelCategory {
                display: flex;
                gap: 5px;
                justify-content: center;
              }
            }
          }
        }
        .epiScenesBox {
          width: 100%;
          display: flex;
          color: #ffffff;
          justify-content: center;
          gap: 25px;
          border: 1px solid #fff;
          border-radius: 20px;
          padding: 3px 20px;
          margin-top: 20px;
          .noEpisode {
            margin: 0;
            white-space: nowrap;
            span {
              color: #fdb03c;
            }
          }
          .noScenes {
            margin: 0;
            white-space: nowrap;
            span {
              color: #fdb03c;
            }
          }
        }
      }
    }
    .noDataMsg {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      margin: 0px auto;
      color: #fff;
      height: 100%;
      &:hover {
        color: #000;
        .Noroleimg {
          transform: rotate(-50deg);
          circle {
            fill: #fff;
            stroke: #fff;
          }
          g {
            path:nth-child(3) {
            fill: #FDB03C;
            }
            path:nth-child(4) {
            fill: #fff;
            }
          }
        }
      }
      .Noroleimg {
        transition: all ease 500ms;
          circle {
            fill: #0a0f12;
            stroke: #fff;
          }
          g {
            path:nth-child(3) {
              fill: #fff;
            }
            path:nth-child(4) {
              fill: #FDB03C;
            }
          }
        }
      .noRoleText {
        font-size: 32px;
        text-align: center;
        position: absolute;
        margin: 0;
        padding: 0 10px;
        cursor: pointer;
      }
    }
  }

  .leftRightHands {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    .leftHandArrow {
      position: absolute;
      left: 0;
      top: 30px;
      display: flex;
      align-items: center;
      gap: 22px;
      &:hover {
        .lefthandtext {
          color: #FDB03C;
        }
        .lefthanfSvg {
          path {
            fill: #FDB03C;
          }
        }
      }
      .lefthandtext {
        font-size: 16px;
        font-weight: 200;
        line-height: 20px;
        color: #fff;
        margin: 0;
      }
      .lefthanfSvg {
        width: 32px;
      }
    }
    .rightHandarrow {
      position: absolute;
      right: 0;
      top: 30px;
      padding-right: 85px;
      display: flex;
      align-items: center;
      gap: 22px;
      &:hover {
        .Righthandtext {
          color: #FDB03C;
        }
        .lefthanfSvg {
          path {
            fill: #fdb03c;
          }
        }
      }
      .Righthandtext {
        color: #fff;
        font-size: 16px;
        font-weight: 200;
        line-height: 20px;
        margin: 0;
      }
      .lefthanfSvg {
        width: 32px;
        path {
          fill: #ffffff;
        }
      }
    }
  }
  .ThirdNocastbox {
    border: 2px solid #fff;
    width: 100%;
    height: 40vh;
    border-radius: 15px;
    padding: 20px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:hover{
      color: #FFF;
      border: 1px solid #FFF;
      background: #FDB03C;
    }
    .modleNoCast {
      font-size: 17px;
      color: #fff;
      font-weight: 200;
      margin: 0;
      padding-top: 30px;
    }
    .PersonPlusSvg {
      width: 52px;
      path {
        fill: #fff;
      }
    }
    .modleBtmSecNocast {
      color: #fff;
      text-align: center;
      font-weight: 200;
      .modleOptionNo {
        color: #fff;
        font-size: 21px;
        text-align: center;
        margin: 0;
        span {
          color: #d49535;
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
}



@media screen and (max-width:1600px) {
  .sceneDetailContainer {
    width: calc(100% - 300px);
    margin-left: 300px;
    padding-left: 30px;
    height: 100%;
    justify-content: space-between;
    .content {
      height: calc(100vh - 73px);
      .headerBox {
        padding: 30px 30px 0 0;
        .firsthead{
          .logotoptext{
            font-size: 30px;
          }
        .sideImgbox {
          padding-right: 6px;
        }
        .logobotText{
          font-size: 20px;
          padding-bottom: 10px;
        }
      }
      .midHeader {
        .midToptxt {
          font-size: 30px;
          margin-bottom: 8px;
        }
        .midbotDiv {
          gap: 15px;
          .midbotinnText {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
      }
      .lastSecHead {
        .lstHeadTopbtn {
          margin-bottom: 0;
          margin-top: 10px;
        }
      }
      }
      .innerCastMainContainer {
        gap: 50px;
        margin: 20px 0px;
        .innerCastMainSec {
          .iconTextLines{
            padding: 15px 0 20px ;
            .logolinetext { 
              display: flex;
              gap: 0;
              .casticonsvg{
                width: 35px;
              }
              .casticonsvgText{
                font-size: 20px;
                line-height: 1.3;
                margin-left: 10px;
              }
            }
          }
          .modelImageBox {
            gap: 30px;
            .modelIndivBox
            {
              width: 16vw!important;
              height: 40vh;
              .modelSing {
                transform: translateY(72px);
                padding: 10px;
                .modelOptionNo{
                  font-size: 18px;
                }
                .modelName{
                  font-size: 24px;
                }
                .modelLabel{
                  font-size: 15px;
                  margin-bottom: 5px;
                  margin-top: -5px;
                }
                .ancherbtnsbox {
                  .anchrCastbtn {
                    font-size: 14px;
                    margin-bottom: 8px;
                  }
                  .anchrRolebtn {
                    font-size: 14px;
                  }
                }
              }
            }
        }
        }
      }
    }
  }
}