.userActionBtn {   
  position: absolute;
  top: 30px;
  right: 30px;
  .actionButton {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
  }
}

.userEditBtn {
  background-color: #d77013!important;
  border-radius: 0!important;
  border:none!important;
  outline: none!important;
  box-shadow: none!important;
  font-weight: 600!important;
  svg {
    width: 15px;
    height: auto;
    margin-right: 5px;
    fill: white;
  }
}

.userDeleteBtn {
  background-color: #dc0807!important;
  border-radius: 0!important;
  border:none!important;
  outline: none!important;
  box-shadow: none!important;
  font-weight: 600!important;
  svg {
    width: 15px;
    height: auto;
    margin-right: 5px;
    fill: white;
  }
}

.accountPhysicalTab {
    margin-top: 3rem;
    display: flex;
    .modalPhyinfo {
      width: 60%;
      .phyinforow {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        position: relative;
        margin-left: -0.2em;
        margin-right: -0.2em;
        .filterItems{
            padding: 0.2em 0.2em;
        }
        .itemData {
          background-color: #1d2c33;
          color: white;
          padding: 0.2em 0.3em;
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          min-height: 37px;
          height: auto;
          .filterHeading {
            margin-right: auto;
            margin-left: 45px;
          }
          .hairColor{
            color: #d67013;
            font-weight: 600;
            min-width: 80px;
            text-align: center;
            margin-left: 50px;
          }
          .eyeColor{
            color: #d67013;
            font-weight: 600;
            min-width: 80px;
            text-align: center;
            margin-left: 50px;

          }
        }        

        // catagories col
        .catagoriesCol {
          min-width: 340px;
          width: 100%;
          .catagoriesData {
            .catagories {
              list-style: none;
              display: flex;
              margin-bottom: 0;
              padding: 0 0.4em;
              width: 80%;
              flex-direction: row;
              flex-wrap: wrap;
              li {
                background-color: #0e1518;
                margin: 0.2em;
                padding: 0 0.8em;
                border-radius: 50px;
                font-size: 0.9em;
                &:last-child {
                  margin-right: 0;
                }
                &:hover {
                  background-color: #d67013;
                  cursor: pointer;
                }
              }
              .selectedCatagoriesType {
                background-color: #d67013;
              }
            }
          }
        }

        // gender col
        .genderCol {
          width: 30%;
          min-width: 130px;
          span {
            background-color: #0e1518;
            padding: 0 0.9em;
            border-radius: 50px;
            &:hover {
              background-color: #d67013;
              cursor: pointer;
            }
          }
          .selectedGender {
            background-color: #d67013;
          }
        }

        // skin col
        .skinCol {
          width: 70%;
          min-width: 370px;
          .skinData {
            .selectedTonetext {
              color: #d67013;
              font-weight: 600;
              min-width: 80px;
              text-align: center;
            }
            .chooseTone {
              list-style: none;
              margin: 0;
              display: flex;
              li {
                width: 22px;
                height: 22px;
                border-radius: 50px;
                margin-left: 0.7em;
                cursor: pointer;
                &:hover {
                  transform: scale(1.3);
                }
              }
              .selectedTone {
                transform: scale(1.3);
              }
            }
          }
        }

        // hair type
        .hairtypeCol {
          min-width: 340px;
          width: 50%;
          .hairtypeData {
            .hairTypeHeading {
              width: 20%;
              margin-left: 45px;
            }
            .hairType {
              list-style: none;
              display: flex;
              margin-bottom: 0;
              padding: 0 0.4em;
              width: 80%;
              flex-direction: row;
              flex-wrap: wrap;
              li {
                background-color: #0e1518;
                margin: 0.2em;
                padding: 0 0.8em;
                border-radius: 50px;
                font-size: 0.9em;
                &:last-child {
                  margin-right: 0;
                }
                &:hover {
                  background-color: #d67013;
                  cursor: pointer;
                }
              }
              .selectedHairType {
                background-color: #d67013;
              }
            }
          }
        }
        
        //social status
        .socialStatusCol {
          min-width: 345px;
          width: 50%;
          .socialStatusData {
            .socialStatusHeading{
              width: 24%;
              margin-left: 8px;
            }
            .socialStatus {
              list-style: none;
              display: flex;
              margin-bottom: 0;
              padding: 0 0.4em;
              width: 75%;
              flex-direction: row;
              flex-wrap: wrap;
              li {
                background-color: #0e1518;
                margin: 0.2em;
                margin-left: 0.9em;
                padding: 0 0.9em;
                border-radius: 50px;
                font-size: 0.9em;
                &:last-child {
                  margin-right: 0;
                }
                &:hover {
                  background-color: #d67013;
                  cursor: pointer;
                }
              }
              .selectedSocialStatus {
                background-color: #d67013;
              }
            }
          }
        }

        //special talent
        .specialTalentCol {
          min-width: 345px;
          width: 100%;
          .specialTalentData {
            .specialTalentHeading {
              width: 24%;
              margin-left: 3px;
            }
            .specialTalent {
              list-style: none;
              display: flex;
              margin-bottom: 0;
              padding: 0 0.2em;
              width: 75%;
              flex-direction: row;
              flex-wrap: wrap;
              li {
                background-color: #0e1518;
                margin: 0.2em;
                margin-left: 0.9em;
                padding: 0 0.9em;
                border-radius: 50px;
                font-size: 0.9em;
                &:last-child {
                  margin-right: 0;
                }
                &:hover {
                  background-color: #d67013;
                  cursor: pointer;
                }
              }
              .selectedSpecialTalent {
                background-color: #d67013;
              }
            }
          }
        }

        // hair color
        .haircolorCol {
          width: 100%;
          .haircolorData {
            .selectedHaircolor {
              color: #d67013;
              font-weight: 600;
              min-width: 80px;
              text-align: center;
            }
            .hairColor {
              list-style: none;
              display: flex;
              margin-bottom: 0;
              padding: 0 0.5em;
              max-width: 70%;
              display: flex;
              flex-wrap: wrap;
              li {
                width: 44px;
                min-width: 44px;
                height: 12px;
                border-radius: 100px;
                margin:0.3em;
                cursor: pointer;
              }
              .selectedHairColor {
                transform: scaleY(1.8);
              }
            }
          }
        }

        // eye color
        .eyecolorCol {
          width: 75%;
          .eyecolorData {
            .eyeColor {
              list-style: none;
              display: flex;
              margin-bottom: 0;
              padding: 0 0.5em;
              li {
                height: 100%;
                margin-right: 0.9em;
                cursor: pointer;
                img {
                  max-height: 25px;
                  width: auto;
                  &:hover {
                    transform: scale(1.4);
                  }
                }
              }
              .selectedEyecolor {
                transform: scale(1.5);
              }
            }
          }
        }

        // height col
        .heightCol {
          width: 25%;
          min-width: 135px;
          .filterSelect {
            padding: 0 0 0 0.3em;
            height: auto;
            border-radius: 50px;
            border: none !important;
            outline: none !important;
            color: white;
            background-color: #d67013;
            box-shadow: none !important;
            max-width: 90px;
            font-weight: 600;
            cursor: pointer;
          }
        }

        // body shape
        .bodyshapeCol {
          min-width: 340px;
          width: 75%;
          .bodyShape {
            list-style: none;
            display: flex;
            margin-bottom: 0;
            padding: 0 0.5em;
            max-width: 80%;
            flex-wrap: wrap;
            li {
              background-color: #0e1518;
              margin: 0.2em;
              padding: 0 0.8em;
              border-radius: 50px;
              font-size: 0.9em;
              &:last-child {
                margin-right: 0;
              }
              &:hover {
                background-color: #d67013;
                cursor: pointer;
              }
            }
            .selectedBodyShape {
              background-color: #d67013;
            }
          }
        }

        // weight col
        .weightCol {
          width:25%;
          min-width: 135px;
          .filterSelect {
            padding: 0 0 0 0.3em;
            height: auto;
            border-radius: 50px;
            border: none !important;
            outline: none !important;
            color: white;
            background-color: #d67013;
            box-shadow: none !important;
            max-width: 90px;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }

    .manualphyInfo {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      .manualinfoCol {
        width: 25%;
        margin-top: 1.5rem;
        h4 {
            color:#d77013;
            font-weight: 700;
            margin: 0;
        }
        p {
            color: white;
            margin: 0;
        }
      }
    }

    .manualphyInfoEdit {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .manualinfoCol {
        width: 24%;
        margin-top: 2.0rem;
        h4 {
            color:#d77013;
            font-weight: 700;
            margin: 0;
        }
      } 
    } 
  }

  // modal img side
  .modelImginfo {
    padding-left: 3rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .imginfoRow {
      display: flex;
      align-items: center;
      .modalimgCol {
        width: 200px;
        height: 200px;
        .userImgBox {                
          background-color: #1d2c33;   
          .userInnerImgBox{
            position: relative;
            padding-bottom: 177%;
            height: 0;
          }            
          div {
            img {
              width: 100%;
              object-fit: cover;
              position: absolute;
              height: 100%;
            }
          }
        }
        p {
          color: white;
          padding-top: 10px;
          text-align: center;
        }
      }
      .modalCat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        margin-left: 6rem;
        width: calc(100% - 200px);
        .catagoriesName {
          margin-left: 1rem;
        }
        h4 {
          color:#d77013;
          font-weight: 700;
          margin: 0;
        }
        p {
          color: white;
          margin: 0;
        }
      }
    }
    .modelNameinfo {        
      text-align: end;
      h4 {
        color:#d77013;
        font-weight: 700;
        margin: 0;
      }
      p {
        color: white;
        margin: 0;
      }
    }
  }
}


