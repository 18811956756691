.userActionBtn {   
    position: absolute;
    top: 30px;
    right: 30px;
    
    .actionButton {
        display: flex;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
    }
}
.subscriptionDetail {
    display: flex;
    margin-top: 80px;
    .subsImgSide {
        width: 30%;
        display: flex;
        justify-content: center;
        .modalimgCol {
            width: 200px;
            .userImgBox {
                background-color: #1d2c33;
                .userImg {
                    width: 100%;
                    transform: translate(5px, 5px); 
                    width: 200px;
					height: 200px;
					object-fit: cover;
                }
            }
            p {
                color: #fff;
                padding-top: 10px;
                text-align: center;
            }
        }
    }

    .subsDetailSide {
        width: 70%;
        display: flex;
        justify-content: flex-start;
        padding: 0 10px;
        .subsdetailRow {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            .subsDetailCol {
                width: 45%;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                .labels {
                    color: #d77013;
                    font-weight: 700;
                    margin: 0;
                    width: 100%
                }
                .viewText {
                    color: white;
                    text-transform: capitalize;
                }
                .subsDateMainTag {
                    display: flex;
                    gap: 7px;
                    .subsDate {
                        background-color: #1d2c33;
                        color: #fff;
                        padding: 2px 10px;
                    }
                }

                .editDiv {
                    width: 50%;
                    input[type="text"] {  //for datepicker
                        width: 100%;
                        padding: 7px;
                        border: none;
                        border-radius: 5px;
                    }            
                    .editPageDetail {
                        width: 100%;
                        padding: 7px;
                        border-radius: 5px;
                        border: none;
                    }
                    input:disabled {
                        background: #fff;
                    }
                }
                .dropdown {
                    width: 50%;
                }      
            }
            .deactivateBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 50px;
                margin-left: 25%;
                button {
                    background-color: #d77013;
                    border: none;
                    border-radius: 0;
                    font-weight: 800;
                    font-size: 1.5rem;
                    &:hover {
                        background-color: #d77013;
                    }
                    &:focus {
                        background-color: #d77013 !important;
                        box-shadow: none !important;
                    }
                }
            }
        }
        .subsdetailRowBox{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70%;
            .notActivatedMsg {
                color: white;
                padding: 10px;
                font-weight: 800;
                font-size: 1.2rem;
                background-color: #d77013;
            }
        }
    }
}

// deactivate account btn css
.deactivateBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    button {
        background-color: #d77013;
        border: none;
        border-radius: 0;
        font-weight: 800;
        font-size: 1.5rem;
        &:hover {
            background-color: #d77013;
        }
        &:focus {
            background-color: #d77013 !important;
            box-shadow: none !important;
        }
    }
}

.signupDetail {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
    .title {
        color:#d77013;
        font-weight: 700;
        font-size: 2rem;
        margin: 0;
    }
    .text {
        color: white;
        font-size: 1.5rem;
        margin: 0;
    }
}

.userEditBtn { 
    background-color: #d77013!important;
    border-radius: 0!important;
    border:none!important;
    outline: none!important;
    box-shadow: none!important;
    font-weight: 600!important;
    svg {
      width: 15px;
      height: auto;
      margin-right: 5px;
      fill: white;
    }
}
  
.userDeleteBtn {
    background-color: #dc0807!important;
    border-radius: 0!important;
    border:none!important;
    outline: none!important;
    box-shadow: none!important;
    font-weight: 600!important;
    svg {
      width: 15px;
      height: auto;
      margin-right: 5px;
      fill: white;
    } 
}