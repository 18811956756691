@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//temporary css
iframe {
  display: none;
}

.slick-slider.slick-initialized .slick-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 500px;
  overflow: hidden;
}

.slickSlider .slick-slide {
  height: auto!important;
}

.normalSidebarcontainer {
  position: absolute;
  top:0;
  width: 85px;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fff;
}

// select option css 
.contentBoxDirector {
  .selectOpt {
    padding: 0 10px;
    height: 32px;
    border-radius: 10px;
    -webkit-appearance: none;
    background-image: url("../src/assets/icons/down-arrow-image.png");
    background-position: right center;
    background-size: 10px;
    background-repeat: no-repeat;
    background-position-x: 95%;
  }
}

.director-footer {
  width: calc(100% - 85px);
  margin-left: 85px;
}
.mainWhiteCricleSvg {
  .sta {
    fill: #fff;
    transition: all ease 400ms;
  }
  .stb {
    fill: #FDB03C;
    transition: all ease 400ms;
  }
  .stc {
    fill: #0a0f12;
    stroke: #FFF;
    transition: all ease 400ms;
  }
}
.lefthanfSvg {
  .st0 {
    fill:#fff;
  }
  .st1 {
    fill: #fff;
  }
}


.orange {
  color: #fdb03c !important
}
