.formClass {
    .required {
      color: red;
    }
    
    input[type=file]::file-selector-button {
      margin-right: 20px;
      border: none;
      background: #f87e0d;
      padding: 5px 10px;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
    }
    
    input[type=file]::file-selector-button:hover {
      background: #ff7b00;
    }

    .submitButton {
        margin-top: 15px;
    }
}