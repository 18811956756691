.videosTab {
    display: flex;
    margin-top: 3%;
    .videoPlayerSide {
        margin-top: 2%;
        .videoPlayer {
            border-top: 8px solid #1d2c33;
            border-left: 8px solid #1d2c33;
            position: relative;
            cursor: pointer;
            .modalVideoDetail {
                position: absolute;
                z-index: 2;
                top: 50%;
                right: 0;
                text-align: right;
                border-right: 16px solid #d77013;
                padding-right: 12px;
                transform: translate(0, -20%);
                h4 {
                    color: #d77013;
                    font-weight: 700;
                    font-size: 24px;
                    margin: 0;
                }
                h5 {
                    color: white;
                    font-weight: 500;
                    font-size: 20px;
                    margin: 0;
                }
            }
            .playButton {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .multiVideoSide {
        padding-left: 50px;
        .multiVideoContainer {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            .multiVideoItem {
                display: flex;
                justify-content: flex-start;
                padding: 20px;
                position:  relative;
                .videoItem {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 340px;
                    height: 190px;
                    max-width: 100%;
                    background-color: #1d2c33;
                    cursor: pointer;
                    position: relative;
                    .ProgressBar{
                        height: 5rem;
						width: 190px;
                    }
                    .playButton {
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .modalVideoDetail {
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        right: 0;
                        text-align: right;
                        border-right: 7px solid #d77013;
                        padding-right: 2px;
                        transform: translate(0, -20%);
                        h5 {
                            color: #d77013;
                            font-weight: 700;
                            font-size: 12px;
                            margin: 0;
                        }
                        p {
                            color: white;
                            font-weight: 500;
                            font-size: 14px;
                            margin: 0;
                        }
                    }
                    img {
                        transform: translate(4px, 6px);
                        width: 340px;
                        height: 190px;
                    }
                    .addVideo {
                        color: white;
                    }
                }
                .remove{
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    width: 30px;
                    cursor: pointer;
                }
            }
        }
        .modalDetail {
            display: flex;
            flex-direction: column;
            text-align: right;
            margin-right: 20%;
            margin-top: 10px;

            h4 {
                color: #d77013;
                font-weight: 700;
                margin: 0;
            }

            p {
                color: white;
                margin: 0;
                font-weight: 500;
            }
        }
    }
}