.head {
  font-size: 30px;
}

.cate {
  padding: 20px;
  background: #1d2c33;
  color: white;
  margin-right: 4px;
}

.darkDiv {
  background: #1d2c33;
  color: white;
}

.main {
  background-color: blue;
}

.filterContainer {
  .filterRow {
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      list-style: none;
      li {
        color: #ffffff;
        font-size: 1.3em;
        font-weight: 600;
        margin-right: 1.5em;
        cursor: pointer;
        &:hover {
          color: #d67013;
        }
        
      }
      li.selected{
        color: #d67013;
      }
    }
    .refreshBtn {
      display: inline-flex;
      padding: 10px 0;
      margin-left: auto;
      span {
        color: #ffffff;
        font-size: 1.3em;
        font-weight: 600;
        margin-right: 0.5em;
      }
      .btn-ref {
        background-color: #d67013;
        font-size: 1em;
        font-weight: 600;
        color: #ffffff;
        padding: 0.1em 1em;
        border: none;
        outline: none;
      }
    }
  }
  // user filter tab
  .usersFiltertab {
    background-color: #1d2c33;
    margin-bottom: 0.2em;
    ul {
      display: flex;
      list-style: none;
      margin-bottom: 0;
      flex-wrap: wrap;
      .subCatagoryHeading {
        padding: 0.5em 0.5em;
        font-size: 1em;
        color: white;
        font-weight: 600;
        border-right: 2px solid black;
      }
      li {
        padding: 0.5em 0.5em;
        font-size: 1em;
        color: white;
        font-weight: 600;
        border-right: 2px solid black;
        cursor: pointer;
        &:hover {
          background-color: #d67013;
        }
      }
    }
  }
  // filterSelection items
  .filterSelection {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-left: -0.2em;
    margin-right: -0.2em;
    .filterItems {
      padding: 0.2em 0.2em;
      .itemData {
        background-color: #1d2c33;
        color: white;
        padding: 0.2em 0.3em;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        min-height: 37px;
        height: auto;
        .filterSelect {
          padding: 0 0 0 0.3em;
          height: auto;
          border-radius: 50px;
          border: none !important;
          outline: none !important;
          color: white;
          background-color: #d67013;
          box-shadow: none !important;
          max-width: 90px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}

// gender selection column
.genderCol {
  width: 12%;
  min-width: 130px;
  .genderData {
    span {
      border-radius: 9.56px;
      padding: 0 0.3em;
      margin: 0;
      line-height: normal;
      cursor: pointer;
      &:hover {
        background-color: #d67013;
      }
    }
  }
}

.selectedCategory {
  background-color: #d67013;
}
.white {
    background-color: white!important;
  }
  .pale-white{
      background-color: #ffeded!important;
  }
  .Brown{
      background-color: Brown!important;
  }
  .dark-Brown{
      background-color: #795548!important;
  }
  .black{
      background-color: black!important;
  }
  .light-brown{
      background-color: #bf9180!important;
  }
  .blonde{
      background-color: #faf0be;
  }
  .red{
      background-color: red;
  }
  .auburn{
      background-color: #A52A2A;
  }
  .grey{
      background-color: grey;
  }
  .dark-grey{
      background-color: gray;
  }
  .dark-blonde{
      background-color: #faf0be;
  }

// age selection column
.ageCol {
  width: 20%;
  min-width: 185px;
}

// skin selection column
.skinCol {
  width: 36%;
  min-width: 370px;
  .skinData {
   
    .selectedTonetext {
      color: #d67013;
      font-weight: 600;
      min-width: 80px;
      text-align: center;
    }
    .chooseTone {
      list-style: none;
      margin: 0;
      display: flex;
      li {
        width: 22px;
        height: 22px;
        border-radius: 50px;
        // background-color: red;
        margin-left: 0.7em;
        cursor: pointer;
        &:hover {
          transform: scale(1.2);
        }
      }
      .selectedTone {
        transform: scale(1.4);
      }
    }
  }
}

// height selection column
.heightCol {
  width: 16%;
  min-width: 135px;
}

// weight selection column
.weightCol {
  width: 16%;
  min-width: 135px;
}

// hair  type column
.hairtypeCol {
  min-width: 340px;
  width: 35%;
  .hairType {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    padding: 0 0.4em;
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      background-color: #0e1518;
      margin: 0.2em;
      padding: 0 0.8em;
      border-radius: 50px;
      font-size: 0.9em;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: #d67013;
        cursor: pointer;
      }
    }
    .selectedHiarType {
      background-color: #d67013;
    }
  }
}
// hair  color column
.haircolorCol {
  width: 65%;
  .haircolorData {
    .selectedHaircolor {
      color: #d67013;
      font-weight: 600;
      min-width: 80px;
      text-align: center;
    }
    .hairColor {
      list-style: none;
      display: flex;
      margin-bottom: 0;
      padding: 0 0.5em;
      max-width: 70%;
      display: flex;
      align-items: center;
      li {
        width: 44px;
        min-width: 44px;
        height: 12px;
        border-radius: 100px;
        margin:0.3em;
        cursor: pointer;
      }
      .selectedHairColor {
        transform: scale(1.5);
        margin: 0.3em 0.8em;
      }      
    }
  }
}

// hair  color column
.eyecolorCol {
  width: 45%;
  .eyecolorData {
    .selectedEyecolor {
      color: #d67013;
      font-weight: 600;
    }
    .eyeColor {
      list-style: none;
      display: flex;
      margin-bottom: 0;
      padding: 0 0.5em;
      li {
        height: 100%;
        margin-right: 0.9em;
        cursor: pointer;
        img {
          max-height: 25px;
          width: auto;
          &:hover {
            transform: scale(1.4);
          }
        }
      }
      .selectedEyeColor {
        transform: scale(1.2);
      }
    }
  }
}

// BODY  SHAPE column
.bodyshapeCol {
  min-width: 340px;
  width: 55%;
  .bodyShape {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    padding: 0 0.5em;
    max-width: 80%;
    flex-wrap: wrap;

    li {
      background-color: #0e1518;
      margin: 0.2em;
      padding: 0 0.8em;
      border-radius: 50px;
      font-size: 0.9em;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: #d67013;
        cursor: pointer;
      }
    }
    .selectedBodyShape {
      background-color: #d67013;
    }
  }
}

// social status column
.socialStatusCol {
  min-width: 340px;
  width: 42%;
  .socialStatus {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    padding: 0 0.4em;
    margin-left: 0.2em;
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      background-color: #0e1518;
      margin: 0.2em;
      margin-left: 0.9em;
      padding: 0 0.9em;
      border-radius: 50px;
      font-size: 0.9em;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: #d67013;
        cursor: pointer;
      }
    }
    .selectedSocialStatusType {
      background-color: #d67013;
    }
  }
}

//special talent column
.specialTalentCol {
  min-width: 340px;
  width: 100%;
  .specialTalentsHeading {
    margin-left: -1.5rem;
  }
  .specialTalents {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    padding: 0 0.2em;
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      background-color: #0e1518;
      margin: 0.1em;
      padding: 0 0.9em;
      border-radius: 50px;
      font-size: 0.9em;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: #d67013;
        cursor: pointer;
      }
    }
    .selectedSpecialTalentsType {
      background-color: #d67013;
    }
  }
}