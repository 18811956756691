.animation{
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 999;
  height: 100vh;
  object-fit: cover;
}

