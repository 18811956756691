.collapseSidebar{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .projrctRep {
    width: calc(100% - 85px) !important;
    margin-left: 85px !important;
    .mainsec {
      width: 40%;
    }
  }
}

.expandSidebar{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.projrctRep {
  width: calc( 100% - 350px );
  padding-top: 3%;
  margin-left: 350px;
  display: flex;
  overflow-x: auto;
  align-items: center;
  flex: 1;
  overflow-y: hidden;
  &::-webkit-scrollbar{
    display: none;
  }
  .mainsec {
    width: 50%;
    flex-shrink: 0;
    transition: all ease 500ms;
    .centerimgboc {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: all ease 400ms;
      border-radius: 50%;
      cursor: pointer;
      width: 75%;
      margin: 0 auto;
      overflow: hidden;
      svg {
        width: 60vh;
        height:60vh;
        transition: all ease 500ms;
      }
      .countryEditBox {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        padding: 3%;
        width: 50vh;
        .EditDeleteBox{
          font-weight: 200;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          gap: 10px;
          justify-content: center;
          transition: all ease 300ms;
          opacity: 0;
          visibility: hidden;
          position: relative;
          .borderTopLine{
            position: absolute;
            width: 60px;
            top:-30px;
            border-top: 1px solid #000;
          }
          a {
            text-decoration: none;
            color: inherit;
          }
        }
        .contrytextName{
          font-weight: 200;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          transition: all ease 300ms;
          opacity: 0;
          visibility: hidden;
        }
        .centerTxtsec {
          font-weight: 200;
          font-size: 5vh;
          line-height: 120%;
          text-align: center;
          margin: 0;
          color: #ffffff;
          transition: all ease 300ms;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }
        .centerLableText {
          font-size: 3vh;
          color: #ffffff;
          transition: all ease 300ms;
          font-weight: 200;
          text-align: center;
          line-height: 120%;
        }
      }
      &:hover{
        transform: translateY(-80px);
        .countryEditBox {
          align-items: center;
          .contrytextName{
            opacity: 1;
            visibility: visible;
            width: 25vh;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .EditDeleteBox{
            opacity: 1;
            visibility: visible;
          }
          .centerTxtsec {
            color: #000;
          }
          .centerLableText {
            color: #000;
          }
        }
        .blkWhitehover{
          circle{
            fill: #FFF;
          }
          path{
            fill: #FFF;
          }
          g{
            path{
              &:nth-child(3){
                fill: #FDB03C;
              }
            }
          }
        }
        svg{
          transform: rotate(-50deg);
        }
      }
    }
  }
  .activeMainsec{
    @extend .mainsec;
    .centerimgboc{
      transform: translateY(-80px);
      .countryEditBox {
        align-items: center;
        .contrytextName{
          opacity: 1;
          visibility: visible;
          width: 25vh;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .EditDeleteBox{
          opacity: 1;
          visibility: visible;
        }
        .centerTxtsec {
          color: #000;
        }
        .centerLableText {
          color: #000;
        }
      }
    }
    svg{
      transform: rotate(-50deg);
    }
    .blkWhitehover{
			g{
				path{
					&:nth-child(3){
						fill: #FDB03C;
					}
				}
			}
      path{
        fill: #FFF;
      }
      circle{
        fill: #FFF;
      }
    }
  }
}

.leftRightHands {
	width: calc(100% - 350px);
	margin-left: 350px;
	display: flex;
	justify-content: center;
	position: relative;
	cursor: pointer;
	.leftHandArrow {
		position: absolute;
		left: 0;
		top: 30px;
		display: flex;
		align-items: center;
		gap: 22px;
		margin-left: 85px;
		&:hover {
			.lefthandtext {
				color: #FDB03C;
			}
			.lefthanfSvg {
				path {
					fill: #FDB03C;
				}
			}
		}
		.lefthandtext {
			font-size: 16px;
			font-weight: 200;
			line-height: 20px;
			color: #fff;
			margin: 0;
		}
		.lefthanfSvg {
			width: 32px;
		}
	}
	.rightHandarrow {
		position: absolute;
		right: 0;
		top: 30px;
		padding-right: 85px;
		display: flex;
		align-items: center;
		gap: 22px;
		&:hover {
			.Righthandtext {
				color: #FDB03C;
			}
			.lefthanfSvg {
				path {
					fill: #fdb03c;
				}
			}
		}
		.Righthandtext {
			color: #FFF;
			font-size: 16px;
			font-weight: 200;
			line-height: 20px;
			margin: 0;
		}
		.lefthanfSvg {
			width: 32px;
			path {
				fill: #ffffff;
			}
		}
	}
}

.loaderInSidebar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .InnerLoader {
    width: 60px;
    height: 60px;
    color: #FDB03C !important;
  }
}

.rightDisable {
  @extend .rightHandarrow;
  visibility: hidden;
}

.leftDisable {
  @extend .leftHandArrow;
  visibility: hidden;
}

.collapseLeftRightHands {
  @extend .leftRightHands;
  width: calc(100% - 85px)!important;
  margin-left: 85px!important;
}

@media screen and (max-width:1600px) {
        .projrctRep {
          .mainsec {
            .centerimgboc{
                width: 70%;
              .countryEditBox {
                justify-content: center;
                .centerTxtsec{
                  font-size: 26px;
                    margin-bottom: 5px;
                    padding: 0 25px;
                      }
                      .centerLableText{
                        font-size: 18px;
                        margin: 0;
                      }
                    }
                    .contrytextName{
                      margin-bottom: 10px;
                      font-size: 16px!important;
                    }
                    &:hover{
                      transform: translateY(-7%);
                    }
                  }
                }
                .EditDeleteBox{
                  margin-top: 30px;
                  grid-gap: 15px!important;
                  .borderTopLine{
                    top: -15px!important;
                  }
                }
              }
              .leftRightHands {
                width: calc(100% - 300px);
                margin-left: auto;
                .leftHandArrow {
                  margin-left: 50px;
                  gap: 15px;
                  .lefthandtext {
                    font-size: 14px;
                  }
                }
                .rightHandarrow {
                  padding-right: 50px;
                  gap: 15px;
                  .Righthandtext {
                    font-size: 14px;
                  }
                }
              }
              .projrctRep {
                width: calc(100% - 300px);
                margin-left: auto;
                .activeMainsec{
                  @extend .mainsec;
                  .centerimgboc{
                    transform: translateY(-7%);
                  }
                }
              }
              
          }