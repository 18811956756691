.ModelsProfile{	
	width: calc(100% - 350px);
	padding: 70px 28px 30px 28px;
	margin-left: 350px;
	display: flex;
	height: calc(100vh - 104px);
	overflow-y: auto;
	.noDatabox {
		display: flex;
		width: 100%;
		.noDataMsg {
			left: 50%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 66%;
			margin: 0px auto;
			color: #fff;
			&:hover {
				color: #000;
				.Noroleimg {
					transform: rotate(-50deg);
					circle {
						fill: #fff;
						stroke: #fff;
					}
					g {
						path:nth-child(3) {
						fill: #FDB03C;
						}
						path:nth-child(4) {
						fill: #fff;
						}
					}
				}
			}
			.Noroleimg {
				transition: all ease 500ms;
					circle {
						fill: #0a0f12;
						stroke: #fff;
					}
					g {
						path:nth-child(3) {
							fill: #fff;
						}
						path:nth-child(4) {
							fill: #FDB03C;
						}
					}
				}
			.noRoleText {
				font-size: 32px;
				text-align: center;
				position: absolute;
				margin: 0;
				padding: 0 10px;
			}
		}
	}
	.profilemainboxes {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 30px 50px;
		margin-left: 95px;
		.profilebox {
			cursor: pointer;
			position: relative;
			width: 12.3%;
			height: 38vh;
			overflow: hidden;
			border-radius: 10px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top;
			}
			.modelprofileName {
				position: absolute;
				width: 100%;
				bottom: 30px;
				text-align: center;
				font-size: 32px;
				font-weight: 200;
				color: #fff;
				transition: all ease 400ms;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				padding: 0;
			}
			.modelDetail {
				position: absolute;
				width: 100%;
				bottom: 0;
				text-align: center;
				font-size: 16px;
				font-weight: 200;
				color: #fff;
				transition: all ease 400ms;
			}
			.ancherbtnsbox {
				position: absolute;
				width: 100%;
				bottom: 0;
				text-align: center;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				transform: translateY(100px);
				transition: all ease 400ms;
				cursor: pointer;
				.ancherbtns {
					width: 80%;
					background: #fff;
					border-radius: 10px;
					font-size: 18px;
					line-height: 21px;
					margin-bottom: 10px;
					color: #000;
					transition: all ease 400ms;
					&:hover {
						background: #FDB03C;
					}
				}
				.removefavourite {
					@extend .ancherbtns;
					background: #ff3e3f;
					color: white;
					font-size: 15px;
					&:hover {
						color: #000;
					}
				}
			}
		}
		.activeTalent {
			@extend .profilebox;
			.ancherbtnsbox {
				position: absolute;
				transform: translateY(0);
				padding-bottom: 5px;
			}
			.modelprofileName {
				bottom: 90px;
			}
			.modelDetail {
				bottom: 60px;
			}
		}
		.profilebox:hover {
			.modelprofileName {
				bottom: 90px;
			}
			.modelDetail {
				bottom: 60px;
			}
			.ancherbtnsbox {
				transform: translateY(0px);
				padding-bottom: 5px;
				.ancherbtns:hover {
					text-decoration: none;
				}
			}
		}		
	}
	.loaderInSidebar {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.InnerLoader{
		  width: 60px;
		  height: 60px;
		  color: #fdb03c !important;
		}
	}
}

.leftRightHands {
  width: calc(100% - 350px);
  margin-left: 350px;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .leftHandArrow {
    position: absolute;
    left: 0;
    top: 30px;
    display: flex;
    align-items: center;
    gap: 22px;
    margin-left: 85px;
		&:hover {
      .lefthandtext {
        color: #FDB03C;
      }
      .lefthanfSvg {
        path{
          fill: #FDB03C;
        }
      }
    }
    .lefthandtext {
      font-size: 16px;
      font-weight: 200;
      line-height: 20px;
      color: #fff;
      margin: 0;
    }
    .lefthanfSvg {
      width:32px;
    }
  }
  .rightHandarrow {
    position: absolute;
    right: 0;
    top: 30px;
    padding-right: 85px;
    display: flex;
    align-items: center;
    gap: 22px;
		&:hover {
      .Righthandtext {
        color: #FDB03C;
      }
      .lefthanfSvg {
        path{
        fill: #fdb03c;
        }
      }
    }
    .Righthandtext {
      color: #fff;
      font-size: 16px;
      font-weight: 200;
      line-height: 20px;
      margin: 0;
    }
    .lefthanfSvg {
      width:32px;
			path{
        fill: #ffffff;
      }
    }
  }
  .rightDisable {
    @extend .rightHandarrow;
    visibility: hidden;
  }
  .leftDisable {
    @extend .leftHandArrow;
    visibility: hidden;
  }
}


@media screen and (max-width:1600px) {
	.ModelsProfile{	
		width: calc(100% - 300px);
		padding: 30px 30px 115px 30px;
		margin-left: 300px;
		overflow: unset;
		height: 100%;
		.profilemainboxes {
			gap: 25px;
			padding-bottom: 150px;
			.profilebox {
				width: calc(18.8% - 23px);
				.modelprofileName {
					font-size: 18px;
					padding: 0 8px;
				}
				.ancherbtnsbox {
					.ancherbtns {
						font-size: 10px;
					}
				}
			}
		}
		.noDatabox {
			.noDataMsg {
				.noRoleText{
					font-size: 26px;
					width: 100%;
					padding: 0 40px;
				}
			}
		}
	}
	.leftRightHands {
		width: calc(100% - 315x);
		margin-left: 300px;
		position: fixed;
		bottom: 0;
		z-index: 1;
		background: #090f12;
		.rightHandarrow {
			padding-right: 50px;
			gap: 15px;
			.Righthandtext {
				font-size: 14px;
			}
		}
		.leftHandArrow {
			margin-left: 50px;
			gap:15px;
			.lefthandtext{
				font-size: 14px;
			}
		}
	}
}