// import for media queries
@import "../../mediaquery";

.mainWrapper {
  background-color: #0a0f12;
  display: flex;
  position: relative;

  .contentBoxDirector {
    width: 100%;
    background-color: #0a0f12;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding: 0;
    height: 100vh;
    overflow-y: auto;
    .footer {
      width: calc(100% - 85px);
      margin-left: 85px;
    }
  }
  .create-edit-project {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
