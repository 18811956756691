.userStatusTextDropDown{
	background: transparent;
	button {
		text-transform: capitalize;
		color: inherit;
		background: transparent;
		font-size: 16px;
		&:hover {
			background: transparent;
		}
	}
}
.userStatusContainer {
	.notViewedColor {
		color: #FDB03C;
		button {
			background: transparent !important;
			box-shadow: none;
			color: #FDB03C !important;
			&:hover{
				color: #FDB03C;
			}
			&:active{
				background: transparent !important;
				color: #FDB03C !important;
			}
		}
	}
	.callBackColor {
		color: skyblue;
		button {
			background: transparent !important;
			box-shadow: none;
			color: skyblue !important;
			&:hover{
				color: skyblue;
			}
			&:active{
				background: transparent !important;
				color: skyblue !important;
			}
		}
	}
	.viewedColor {
		color: #1db11d;
		button {
			background: transparent !important;
			box-shadow: none;
			color: #1db11d !important;
			&:hover{
				color: #1db11d;
			}
			&:active{
				background: transparent !important;
				color: #1db11d !important;
			}
		}
	}
	.noAnswerColor {
		color: red;
		button {
			background: transparent !important;
			box-shadow: none;
			color: red !important;
			&:hover{
				color: red;
			}
			&:active{
				background: transparent !important;
				color: red !important;
			}
		}
	}
}