.contentBoxDirector {
  .MuiSlider-root {
    width: 86% !important;
    height: 5px !important;
    margin-left: 25px;
  }

  .MuiSlider-rail {
    height: 5px !important;
    border-radius: 20px !important;
  }

  .MuiSlider-track {
    height: 5px !important;
  }

  .MuiSlider-thumb {
    width: 15px !important;
    height: 15px !important;

    .PrivateValueLabel-label-5, .jss5 {
      color: #000;
    }
  }
  .iconMainDiv {
    position: relative;
      width: 100%;
    .iconReset {
      position: absolute;
      height: 24px;
      width: 24px;
      right: -22px;
      top: 0;
      cursor: pointer;
    }
    svg {
      height: 100%;
        width: 100%;
    }
  }
}