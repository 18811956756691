.headerBox {
	width: 100%;
	.header {
		width: calc(100% - 350px);
		margin-left: 350px;
		display: flex;
		align-items: center;
		padding: 40px 85px 0;
		justify-content: space-between;
		.firsthead {
			display: flex;
			.logotoptext {
				font-weight: 200 !important;
				font-size: 5.12vh;
				line-height: 5.6vh;
				padding-left: 26px;
				color: #fff;
			}
			.logobotText {
				font-weight: 200;
				font-size: 3.36vh;
				line-height: 3.65vh;
				color: #FDB03C;
				padding-left: 26px;
				padding-bottom: 31px;
				margin: 0;
			}
		}
		.midHeader {
			.midToptxt {
				font-weight: 200;
				font-size: 33px;
				line-height: 40px;
				color: #FDB03C;	
				text-align: center;
			}
			.midbotDiv {
				display: flex;
				justify-content: space-around;
				gap: 30px;
				.midbotinnText {
					color: #fff;
					font-size: 18px;
					font-weight: 200;
				}
				.countLength {
					color: #FDB03C;
				}
			}
		}
		.lastSecHead {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			min-width: 227px;
			.lstHeadTopbtn {
				width: 100%;
				border: 1px solid #fff;
				padding: 5px;
				color: #fff;
				font-size: 16px;
				line-height: 16px;
				border-radius: 20px;
				text-align: center;
				margin-bottom: 10px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
				&:hover{
					color: #FFF;
					border: 1px solid #FFF;
					background: #FDB03C;
				  }
				.spinnerContainer{
					background: #000;
					inset: 0;
					position: absolute;
					z-index: 5;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					.loadingSpinner{
						color: #fff;
						height: 22px;
						width: 22px;
						border: .15em solid currentcolor;
						border-right-color: transparent;
					}
				}
			}
			.lstInnerhead {
				width: 100%;
				display: flex;
				gap: 10px;
				.lstHeadBtmbtn {
					width: 50%;
					border: 1px solid #fff;
					padding: 5px;
					color: #fff;
					font-size: 16px;
					line-height: 16px;
					border-radius: 20px;
					text-align: center;
					cursor: pointer;
					&:hover{
						color: #FFF;
						border: 1px solid #FFF;
						background: #FDB03C;
					  }
				}
			}
		}
		.createNewEpisode {
			width: 20%;
			cursor: pointer;
		}
	}

	.collapseSidebar {
		@extend .header;
		width: calc(100% - 85px)!important;
		margin-left: 85px!important;
	}
}



@media screen and (max-width:1600px) {
.headerBox {
	
	.header {
		padding: 30px 30px 0px;
		width: calc(100% - 300px);
		margin-left: auto;
		.firsthead {
			svg{
				width: 60px;
			}
			.logobotText{
				padding-bottom: 10px;
				font-size: 20px;
			}
			.logotoptext{
				font-size: 30px;
			}
		}
		.midHeader {
			.midToptxt {
				font-size: 30px;
				margin-bottom: 8px;
			}
			.midbotDiv {
				gap:15px;
			}
		}
	}
	
}
}