.sideBigMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 350px;
  border-right: 1px solid #fff;
  padding: 50px 30px;
  overflow-y: auto;
  transition: all ease 400ms;
  -webkit-transition: all ease 400ms;
  &::-webkit-scrollbar {
    display: none;
  }
  .noDataMsg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0px auto;
    color: #fff;
    &:hover {
      color: #000;
      .Noroleimg {
        transform: rotate(-50deg);
        circle {
          fill: #fff;
          stroke: #fff;
        }
        g {
          path:nth-child(3) {
            fill: #FDB03C;
          }
          path:nth-child(4) {
            fill: #fff;
          }
        }
      }
    }
    .Noroleimg {
      transition: all ease 500ms;
      width: 100%;
      circle {
        fill: #0a0f12;
        stroke: #fff;
      }
      g {
        path:nth-child(3) {
          fill: #fff;
        }
        path:nth-child(4) {
          fill: #FDB03C;
        }
      }
    }
    .noRoleText {
      font-size: 24px;
      text-align: center;
      position: absolute;
      margin: 0;
      padding: 0 10px;
    }
  }
  .loaderInSidebar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .InnerLoader {
      width: 60px;
      height: 60px;
      color: #FDB03C !important;
    }
  }
  .sideImglogo {
    display: flex;
    .sideImgbox {
      padding-right: 28px;
      .sideimgsvgicon {
        width: 45px;
      }
      .sideimgsvgtxt {
        font-size: 12px;
        line-height: 15px;
        font-weight: 200;
        color:#fff;
        margin: 0;
        padding-top: 3px;
      }
    }
    .sideBarlogoTxt {
      .sidelgtxt {
        font-size: 25px;
        line-height: 30px;
        font-weight: 200;
        color:#fff;
        padding-top: 10px;
      }
      .sidelgsubtxt {
        line-height: 20px;
        font-weight: 200;
        color:#FDB03C;
        margin: 0;
        font-size: 16px
      }
    }
  }
  .silelogosubText {
    font-size: 16px;
    color: #fff;
    font-weight: 200;
    line-height: 22px;
    padding: 30px 0;
  }
  .ulListsb {
    list-style: none;
    transition: all ease 400ms;
    .lilistsb {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 25px 0 45px;
      cursor: pointer;
      font-size: 21px;
      line-height: 32px;
      font-weight: 200;
      color: #0e1518;
      background-color: #fff;
      border-radius: 20px;
      height: 32px;
      position: relative;
      text-align: center;
      margin-bottom: 22px;
      .listIconName{
        display: inline-block;
      }
      .listiconsb {
        width: 44px;
        border-radius: 50%;
        border: 6px solid #040805;
        height: 44px;
        position: absolute;
        left: -6px;
        top: -6px;
        background-color: #040805;
      }
    }
    .lilistsb:hover{
      background-color: #FDB03C;
    }
    .selectedList {
      @extend .lilistsb;
      background-color: #d49535;
    }
  }
  .silebotsubText {
    font-size: 16px;
    color: #fff;
    font-weight: 200;
    line-height: 22px;
    padding-top: 30px;
    text-align: center;

  }
  .arrowcontainer { 
    list-style: none;
    transition: all ease 400ms;
    .arrowbox {
      text-align: center;
      font-size: 16px;
      font-weight: 200;
      color: #fff;
      line-height: 32px;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 20px;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;
      .dblarrowSvg {
        width:12px;
        position: absolute;
        left: 18px;
        top: 9px;
      }
      &:hover{
        background-color: #FDB03C;
      }
    }
    .lastarrowbox {
      margin-top: 30px;
      cursor: pointer;
    }
    .backButton {
      cursor: pointer;
    }
    a {
      color: #fff;
      text-decoration: none;
    }      
  }
  .disable {
    @extend .arrowbox;
    pointer-events: none;
    opacity: 0.3;
    cursor: default;
  }
  .collapseExpandBtn {
    position: absolute;
    right: 25px;
    top: 60px;
    cursor: pointer;
    img {
      width: 30px;
    }
  }
}

.toggleSidebar {
  @extend .sideBigMenu;
  overflow: visible;
  width: 85px;
  .sideImglogo {
    display: none!important;
  }
  .silelogosubText {
    display: none;
  }
  .ulListsb{
    padding-top: 84px;
  }
  .lilistsb {
    padding: 0 !important;
    width: 32px;
  }
  .arrowbox {
    padding: 0 16px;
    span {
      display: none;
    }
    &:hover {
      span {   
        display: inline-block;
        position: absolute;
        white-space: nowrap;
        left: 29px;
        width: 250px;
        text-align: center;
        top: -1px;
        background: #FDB03C;
        border-radius: 0 20px 20px 0;
        height: 34px;
        z-index: 1;
      }
    }
    &:hover::before {       
      content: "";
      position: absolute;
      top: -3px;
      border-radius: 20px;
      width: 39px;
      height: 39px;
      border: 3px solid #0a0f12;
      left: -3px;
      z-index: 5;
    }
  }
  .silebotsubText {
    display: none;
  }
  .dblarrowSvg {
    width: 10px !important;
    position: relative !important;
    left: -5px !important;
    top: 0 !important;
  }
  .lilistsb:hover {
    width: 300px !important;
    position: relative;
    background: #d49535 !important;
    z-index: 9;
  }
  .noDataMsg{
    .noRoleText{
      font-size: 16px!important;
      line-height: 1.3;
    }
    &:hover {
      color: #fff!important;
    }
    .Noroleimg{
      display: none;
    }
  }
}



@media screen and (max-width:1600px) {
    .sideBigMenu {
      padding: 30px 30px;
      width: 300px;
      .ulListsb{
        .lilistsb{
          .listIconName{
            font-size: 16px;
          }
        }
      }
      .silelogosubText{
        padding-bottom: 0px;
      }
      .sideImglogo {
        display: flex;
        .sideImgbox {
          padding-right: 15px;
        }
        .sideBarlogoTxt {
          .sidelgtxt {
            padding-top: 0px;
            font-size: 22px;
          }
        }
      }
      .collapseExpandBtn {
        top: 40px;
      }
      .noDataMsg{
        width: 90%;
        margin: 20px auto;
        .noRoleText{
          font-size: 22px;
        }
      }
    }
    .toggleSidebar {
      @extend .sideBigMenu;
      width: 85px;
    }
    .lastarrowbox {
      margin-top: 25px;
    }
  }
  