.photosTab{
	display: flex;
	margin-top: 3%; 
	.singleSlideTab {
		width: 26%;
		padding: 0 30px;
		.singleSlideCol {
			text-align: center; 
			.slideImgBox {
				display: block;
				position: relative;
        padding-bottom: 177%;
				img {                
					width: 100%;
					position: absolute;
					height: 100%;
					object-fit: cover;
				}
			}
		}

	}
	
	// multi slide data
	.multiSlideSide {
		width: 100%;
		display: flex;
		margin-left: 30px;
		margin-top: 0;
		flex-direction: column;
		gap: 60px;
		.multiSlideSideContainer {     
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			gap: 40px;
			.multislideItem {		
				width: calc(16.66% - 33.3px);				
				.photoImgBox {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 0;
					background-color: #1d2c33;
					cursor: pointer;
					position: relative;
					padding-bottom: 177%;
					.userImage {                
						width: 100%;
						height: 100%;
						position: absolute;
						object-fit: cover;
						top: 0;
					}
					.remove {
						position: absolute;
						right: -10px;
						top: -5px;
						width: 30px;
					}
				}
				.addPhotoContainer {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					min-height: 200px;
					height: 100%;
					background-color: #1d2c33;
					cursor: pointer;
					position: relative;
					.photoClickBox {
						flex-direction: column;
						display: flex;
						gap: 10px;
						align-items: center;
						form {
							height: 0;
							width: 0;
							overflow: hidden;
						}
						.ProgressBar {
							height: 5rem;
							width: 190px;
						}
					}
				}
				.inputFile {
					height: 0;
					width: 0;
					opacity: 0;
				}
				.addPhoto{
					color: white;
				}
			}
		}
		
  	.modalDetail {
			display: flex;
			flex-direction: column;
			margin-left: auto;
			justify-content: flex-end;
			margin-top: 15px;
			text-align: right;
			padding-right: 40px;
			h4 {
					color:#d77013;
					font-weight: 700;
					margin: 0;
			}
			p {
					color: white;
					margin: 0;
					font-weight: 500;           
			}
    }
	}
}
  
