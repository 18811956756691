.login-wrraper {
  background-color: red;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;

  .loginCol{
    width:calc(100% / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(2){
        background-color: rgba(40, 50, 48, 0.678);            
        height: 100%;
        min-height: 100vh;
    }
    .form-col {
      width: 100%;
      .signText{
        font-size: 50px;
        color: rgb(255, 255, 255);
        text-align: center;
        margin-bottom: 1em;
      }
    }
  }
}

.login-admin {
  @extend .login-wrraper;
  background-image: url("../images/login-page-background.png");
}

.login-director {
  @extend .login-wrraper;
  background-image: url('https://wallpaperaccess.com/full/427852.jpg');
}

.helperRow{
    display: flex;
    color: white;
    justify-content: space-between;
    margin-bottom: 1em;

    .forgotText{
        font-size: 16px;
        cursor: pointer;
    }
}

.login-form{
    width: 400px;
    max-width: 100%;
    margin: auto;
}

.rememberCheck .form-check{
    display: flex;
    
}
.inputFieldAdmin {
  .input-group>.form-control:not(:first-child) {
    background: #fff;
    color: #000;
  }
}
.form-check-label {
  cursor: pointer;
}
.loginBtn {
    display: flex;
    text-decoration: none;
    font-size: 18px;

    button{
        background-image:linear-gradient(1720deg, rgba(117, 131, 134, 0.99608),  rgb(250,193,26));
        border: none!important;
       width: 160px;
       padding: 0.4em 0;
       border-radius: 50px;
       margin: auto;
       text-align: center;
       box-shadow: none!important; 
       font-size: 18px;
       font-weight: 600;   
    }   
      
}