.collapseSidebar {
  @extend .rolesBreakDownContainer;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% - 85px) !important;
  margin-left: 85px !important;
  .modelIndivBox {
    width: 12vw !important;
  }
}

.rolesBreakDownContainer {
  width: calc(100% - 350px);
  margin-left: 350px;
  padding-left: 85px;
  .content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 104px);
    overflow-y: auto;
    &::-webkit-scrollbar{
      display: none;
    }
    .headerBox {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 40px 85px 0 0;
      justify-content: space-between;
      position: relative;
      .firsthead {
        display: flex;
        gap: 25px;
        left: 0;
        .logotoptext {
          font-weight: 200 !important;
          font-size: 42px;
          line-height: 120%;
          color: #fff;
        }
        .logobotText {
          font-weight: 200;
          font-size: 25px;
          line-height: 3.65vh;
          color: #FDB03C;
          padding-bottom: 31px;
          margin: 0;
          span {
            color: #56db82;
          }
        }
      }
      .midHeader {
        .midToptxt {
          font-weight: 200;
          font-size: 33px;
          line-height: 40px;
          color: #fff;
          text-align: center;
        }
        .midbotDiv {
          display: flex;
          justify-content: space-around;
          gap: 30px;

          .midbotinnText {
            color: #fff;
            font-size: 33px;
            font-weight: 200;
            span {
              color: #FDB03C;
            }
          }
        }
      }
      .lastSecHead {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        .lstInnerhead {
          width: 100%;
          display: flex;
          gap: 10px;
          .lstHeadBtmbtn {
            width: 7rem;
            border: 1px solid #fff;
            padding: 7px;
            color: #fff;
            font-size: 16px;
            line-height: 16px;
            border-radius: 20px;
            text-align: center;
            cursor: pointer;
            &:hover{
              color: #FFF;
              border: 1px solid #FFF;
              background: #FDB03C;
            }
          }
        }
      }
    }
    .innerCastMainSec {
      padding-right: 85px;
      width: 100%;
      .modelImageLineBox {
        display: flex;
        flex-wrap: wrap;
      }
      .iconTextLines {
        width: 100%;
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
        margin: 35px 0 20px;
        position: relative;
        .iconTextBox{
          display: inline-block;
          padding: 0 20px;
          background: #0a0f12;
          position: relative;
          z-index: 1;
          .casticonsvg {
            width: 45px;
          }
          .casticonsvgText {
            color: #56db82;
            font-size: 25px;
            margin: 0;
          }
        }
        .lineBorder {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          height: 1px;
          background-color: #fff;
        }
      }
      .modelImageBox {
        display: flex;
        overflow-x: auto;
        margin: 0px auto;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        .modelImageContainer {
          display: flex;
          gap: 30px;
          max-width: 100%;
          overflow-x: auto;
          cursor: grab;
          &::-webkit-scrollbar{
            display: none;
          }
        }
        .modelIndivBox {
          width: 13vw;
          position: relative;
          overflow: hidden;
          flex-shrink: 0;
          cursor: pointer;
          &:hover {
            .modelSing {
              transform: translateY(0);
            }
          }
          .SinglModeImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
          }
          .modelSing {
            position: absolute;
            bottom: 0;
            padding: 10px 20px;
            left: 0;
            width: 100%;
            transform: translateY(55px);
            transition: all ease 500ms;
            .modleName {
              font-size: 36px;
              color: #fff;
              font-weight: 200;
              text-align: center;
              line-height: 120%;
              margin-bottom: 40px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              overflow: hidden;
            }
            .modlelabel {
              color: #fff;
              font-size: 17px;
              font-weight: 200;
              text-align: center;
              margin-top: -20px;
            }
            .modleOptionNo {
              color: #fff;
              font-size: 21px;
              text-align: center;
              margin: 0;
              span {
                color: #d49535;
              }
            }
            .ancherbtnsbox {
              .anchrCastbtn {
                width: 100%;
                margin-top: 10px;
                background-color: #fff;
                color: #000;
                border-radius: 5px;
                padding: 2px;
                text-align: center;
                background-color: #ff3e3f;
                color: #FFF;
                &:hover{
                  color: #FFF;
                  border: 1px solid #FFF;
                  background: #FDB03C;
                }
              }
            }
          }
          .ThirdNocastbox {
            border: 2px solid #fff;
            width: 100%;
            height: 46vh;
            border-radius: 15px;
            padding: 20px 20px 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            &:hover{
              color: #FFF;
              border: 1px solid #FFF;
              background: #FDB03C;
            }
            .modleNoCast {
              font-size: 17px;
              color: #fff;
              font-weight: 200;
              margin: 0;
              padding-top: 30px;
            }
            .PersonPlusSvg {
              width: 52px;
              path {
                fill: #fff;
              }
            }
            .modleBtmSecNocast {
              color: #fff;
              text-align: center;
              font-weight: 200;
              .modleOptionNo {
                color: #fff;
                font-size: 21px;
                text-align: center;
                margin: 0;
                span {
                  color: #d49535;
                }
              }
            }
          }
          a {
            text-decoration: none;
          }
        }
      }
      .roleDescription {
        text-align: center;
        border: 1px solid #fff;
        border-radius: 25px;
        padding: 10px 20px 10px;
        .roleDescTitle {
          color: #FDB03C;
          font-size: 20px;
          font-weight: 200;
          margin-bottom: 5px;
        }
        .roleDescText {
          color: #fff;
          font-size: 18px;
          font-weight: 200;
          margin-bottom: 0px;
        }
      }
    }
  }
  .leftRightHands {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    .leftHandArrow {
      position: absolute;
      left: 0;
      top: 30px;
      display: flex;
      align-items: center;
      gap: 22px;
      &:hover {
        .lefthandtext {
          color: #FDB03C;
        }
        .lefthanfSvg {
          path {
            fill: #FDB03C;
          }
        }
      }
      .lefthandtext {
        font-size: 16px;
        font-weight: 200;
        line-height: 20px;
        color: #fff;
        margin: 0;
      }
      .lefthanfSvg {
        width: 32px;
      }
    }
    .rightHandarrow {
      position: absolute;
      right: 0;
      top: 30px;
      padding-right: 85px;
      display: flex;
      align-items: center;
      gap: 22px;
      &:hover {
        .Righthandtext {
          color: #FDB03C;
        }
        .lefthanfSvg {
          path {
            fill: #fdb03c;
          }
        }
      }
      .Righthandtext {
        color: #fff;
        font-size: 16px;
        font-weight: 200;
        line-height: 20px;
        margin: 0;
      }
      .lefthanfSvg {
        width: 32px;
        path {
          fill: #ffffff;
        }
      }
    }
  }
  .noDataMsg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    margin: 0px auto;
    color: #fff;
    height: 100vh;
    &:hover {
      color: #000;
      .Noroleimg {
        transform: rotate(-50deg);
        circle {
          fill: #fff;
          stroke: #fff;
        }
        g {
          path:nth-child(3) {
          fill: #FDB03C;
          }
          path:nth-child(4) {
          fill: #fff;
          }
        }
      }
    }
    .Noroleimg {
      transition: all ease 500ms;
        circle {
          fill: #0a0f12;
          stroke: #fff;
        }
        g {
          path:nth-child(3) {
            fill: #fff;
          }
          path:nth-child(4) {
            fill: #FDB03C;
          }
        }
      }
    .noRoleText {
      font-size: 32px;
      text-align: center;
      position: absolute;
      margin: 0;
      padding: 0 10px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width:1600px) {
  .rolesBreakDownContainer {
    width: calc(100% - 300px);
    margin-left: auto;
    padding-left: 30px;
    height: 100%;
    justify-content: space-between;
    .content {
      height: calc(100vh - 73px);
      .headerBox {
        padding: 30px 30px 0 0;
        .firsthead {
          display: inline-block;
          width: 33.33%;
          svg{
            width: 60px;
            float: left;
            margin-right: 20px;
          }
          .logotoptext{
            font-size: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .logobotText{
            font-size: 20px;
            padding-bottom: 10px;
          }
        }
        .midHeader {
          width: 33.33%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .midToptxt {
            font-size: 30px;
            margin-bottom: 8px;
          }
          .midbotDiv {
            gap:15px;
            .midbotinnText {
              font-size: 20px;
              margin-bottom: 10px;
            }
          }
      }
      .lastSecHead {
        width: 33.33%;
        .lstInnerhead{
          justify-content: end;
        }
      }
      }
      .innerCastMainSec {
        padding-right: 30px;
        .iconTextLines{
          .iconTextBox{
            display: flex;
            .casticonsvg{
              width: 35px;
            }
            .casticonsvgText{
              font-size: 20px;
              margin-left: 10px;
            }
          }
        }
        .modelImageBox { 
          .modelIndivBox {
             width: 13vw !important;
             .modelSing{
              padding: 10px 10px;
               .modleName {
                  font-size: 24px;
                  margin-bottom: 30px;
                }
               .modleOptionNo {
                  font-size: 18px;
                }
               .modlelabel{
                font-size: 15px;
               }
              }
              .ThirdNocastbox {
                .modleBtmSecNocast {
                  .modleOptionNo{
                    font-size: 18px;
                  }
                }
                .PersonPlusSvg{
                  width: 40px;
                }
              }
          }
        }
      }
    }
    .leftRightHands{
      .rightHandarrow{
       padding-right: 50px;
       gap: 15px;
       .Righthandtext {
        font-size: 14px;
       }
      }
      .leftHandArrow{
        gap:15px;
      }
   }
  }
}

