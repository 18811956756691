.createRoleHeading {
	font-size: 16px;
	text-align: center;
	color: #FDB03C;
	font-weight: 200;
}
.createRoleInput {
	background: transparent;
	border-radius: 20px;
	text-align: center;
	height: 40px;
	font-weight: 200;
	color: #fff;
	box-shadow: none;
	border-color: #FDB03C;
	margin-bottom: 15px;
	&::placeholder{
		color: #fff;
		font-weight: 200;
	}
	&:focus {
		background: transparent;
		color: #fff;
		border-color: #FDB03C;
		outline: none;
		box-shadow: none;
	}
}
.createRoleDescriptionInput {
	background: transparent;
	border-radius: 20px;
	text-align: center;
	height: 110px !important;
	overflow: hidden;
	font-weight: 200;
	color: #fff;
	box-shadow: none;
	border-color: #FDB03C;
	margin-bottom: 15px;
	&::placeholder{
		color: #fff;
		font-weight: 200;
	}
	&:focus {
		background: transparent;
		color: #fff;
		border-color: #FDB03C;
		outline: none;
		box-shadow: none;
	}
}
.showErrorInput {
	@extend .createRoleInput;
	border-color: #dc3545;
}
.showError {
	font-size: 15px;
	text-align: center;
	color: #dc3545;
	font-weight: 200;
	margin-top: -15px;
}
.createRoleBtn {
	font-size: 16px;
	text-align: center;
	color: #fff;
	font-weight: 200;
	border: 1px solid #fff;
	border-radius: 20px;
	padding: 5px 0;
	margin-top: 15px;
	cursor: pointer;
	&:hover {
		background: #FDB03C;
	}
}