.sidebar {
    display: flex;
    .sbImgbox{
      .sbIcontxt{
        font-weight: 200;
        font-size: 12px;
        line-height: 15px;
        color: #fff;
        padding-top: 3px;
      }
    }
}
