.projects {
	color: #fff;
	display: flex;
	flex-direction: column;
	width: 100%;
	.header {
		width: calc(100% - 85px);
		display: flex;
		align-items: center;
		padding: 0 0 40px;
	}
}