.white {
  background-color: white !important;
}
.paleWhite {
  background-color: #ffeded !important;
}
.brown {
  background-color: Brown !important;
}
.darkBrown {
  background-color: #795548 !important;
}
.black {
  background-color: black !important;
}
.lightBrown {
  background-color: #bf9180 !important;
}
.blonde {
  background-color: #faf0be;
}
.red {
  background-color: red;
}
.auburn {
  background-color: #a52a2a;
}
.gray {
  background-color: grey;
}
.dark-grey {
  background-color: gray;
}
.dark-blonde {
  background-color: #faf0be;
}
.amber {
  background-color: #FFBF00;
}
.green {
  background-color: #00FF00;
}
.hazel {
  background-color: #8E7618;
}
.blue {
  background-color: #00008B;
}
.darkBlonde {
  background-color: #F0E2B6;
}