
@import "../../mediaquery";
.sidebarWrapper{
    height: 100%;
    position: fixed;
    width: 175px;
  .topRow {
    position: relative;
    // toplogo css start
    .topLogo{
        width: 100%;
        padding: 1em 0.2em;
        display: flex;
        justify-content: center;
        img{
            width: 150px;
            max-width: 100%;
        }
    }
    // userbox css start
    .userBox{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 1em;
        background-color: #1d2c33;
        img{
            max-width: 100%;
            width: 40px;
            height: 40px;
            margin-right: 0.5em;
        }
        .userName{
            text-align: center;
            h4{
                font-size: 1em;
                margin: 0;
                color: white;
            }
            p{
                font-size: 0.5em;
                margin: 0;
                color: #ffffff;
            }
        }
    }
     //sidebarlinks container
    .sidebarLinks{
        margin-top: 0.5em;
        cursor: pointer;
        ul{
            a{
                font-size: 1.2em;
                font-weight: 600;
                color: #ffffff;
                text-decoration: none;
                text-align: center;
                background-color: #1d2c33;
            }      
            li{
                text-align: center;
                background-color: #1d2c33;
                padding: 0.5em 0.3em;
                margin-bottom: 1px;
                &:hover{
                    background-color: #d67013;
                }               
            }
            .selectedMenu{
                background-color: #d67013;
            }
        }
    }
}
    //logout container
    .bottomRow{
        position: absolute;
        bottom: 0;
        background-color: #1d2c33;
        left: 0;
        right: 0;              
    .logoutBox{
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        align-items: center;
        cursor: pointer;
        span{
            padding: 0.5em 0.3em;
            a{
                font-size: 1.2em;
                font-weight: 600;
                color: #ffffff;
                text-decoration: none;
            }
        }
    }
}
}