.mainHeader {
    width: 100%;
    gap: 50px;
    margin-top: 3rem;
    .toptext {
      color: #fff;
      font-size: 20px;
      text-align: right;
      position: absolute;
      right: 50px;
      top: 3rem;
    }
    .logoBox {
        display: flex;
        align-items: center;
        margin-left: 3rem;
        gap: 40px;
        .logoImg {
            padding-top: 15px;
        }
        .logotext {
            .logotopText {
                color: #fff;
                font-size: 50px;
                font-weight: 200;
                line-height: 100%;
            }
            .logobottomText {
                color: #fdb03c;
                font-size: 33px;
                font-weight: 200;
                line-height: 100%;
            }
        }
    }
    .detailBreakDwn {
        display: flex;
        padding-top: 20px;
        margin-left: 3rem;
        .proBtn {
            width: 13%;
            border: 1px solid #fff;
            border-radius: 20px;
            text-align: center;
            font-size: 20px;
            color: #fff;
            padding: 5px;
            cursor: pointer;
        }
        .selectedProBtn {
          width: 13%;
          border: 1px solid #fff;
          border-radius: 20px;
          text-align: center;
          font-size: 20px;
          color: #000;
          background: #fdb03c;
          padding: 5px;
          cursor: pointer;
        }
        .breakBtn {
          width: 13%;
          border: 1px solid #fff;
          border-radius: 20px;
          text-align: center;
          font-size: 20px;
          color: #fff;
          padding: 5px;
          margin-left: 2%;
          cursor: pointer;
        }
        .selectedBreakBtn {
            width: 13%;
            border: 1px solid #fff;
            border-radius: 20px;
            text-align: center;
            font-size: 20px;
            color: #000;
            padding: 5px;
            background: #fdb03c;
            margin-left: 2%;
            cursor: pointer;
        }
    }
    
}
.breakDownPage {
    width: 100%;
    display: flex;
    gap: 50px;
    .leftSideDesign {
        width: 30%;
        margin-left: 5rem;
          .roleTotalNum {
            color: #fff;
            font-size: 20px;
            padding: 50px 0 25px;
          }
          .disable {
            @extend .arrowbox;
            pointer-events: none;
            opacity: 0.3;
            cursor: default;
          }
          .rolePagination {
            position: absolute;
            bottom: 30px;
            .silebotsubText {
              font-size: 16px;
              color: #fff;
              font-weight: 200;
              line-height: 22px;
              padding-top: 30px;
              text-align: center;
            }
            .arrowbox {
              width: 21.5rem;
              margin-left: 5px;
              text-align: center;
              font-size: 16px;
              font-weight: 200;
              color: #fff;
              line-height: 32px;
              text-align: center;
              border: 1px solid #fff;
              border-radius: 20px;
              margin-bottom: 10px;
              position: relative;
              list-style-type: none;
              cursor: pointer;
        
              &:hover {
                background-color: #FDB03C;
              }
        
              .dblarrowSvg {
                width: 12px;
                position: absolute;
                left: 18px;
                top: 9px;
              }
            }
          }
        
          .rolesList {
            .innerRolesList {
              display: flex;
              width: 70%;
              cursor: pointer;
              margin-top: 10px;
              .listicons {
                width: 40px;
                background: #040805;
                padding: 4px;
                z-index: 5;
                border-radius: 50%;
                transition: all ease 400ms;
              }
        
              .listIcontext {
                width: 100%;
                color: #000;
                font-size: 20px;
                font-weight: 100;
                text-align: center;
                padding-top: 3px;
                background: #fff;
                border-radius: 0 20px 20px 0;
                margin: 2px 0px 2px -20px;
                transition: all ease 400ms;
              }
        
              .selectedListIcontext {
                width: 100%;
                color: #000;
                font-size: 20px;
                font-weight: 100;
                text-align: center;
                padding-top: 3px;
                background: #fdb03c;
                border-radius: 0 20px 20px 0;
                margin: 2px 0px 2px -20px;
                transition: all ease 400ms;
              }
        
              &:hover {
                .listicons {
                  transform: rotateZ(-50deg);
                }
                .listIcontext {
                  background: #fdb03c;
                }
              }
            }
          }
        }
    .rightSideDesign {
        width: 70%;
        margin-top: -5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;
        .castBrkBox {
            width: 70%;
            margin: 0 10% 38px;
            .castTitle{
                color: #fff;
                font-size: 28px;
                font-weight: 200;
                text-align: center;
                line-height: 100%;
            }
            .detailNumbBox{
                display: flex;
                gap: 40px;
                justify-content: center;
                .detailtxt{
                    color: #fff;
                    font-size: 28px;
                    font-weight: 200;
                    span{
                        color: #fdb03c;
                    }
                }
            }
        }
        .iconTileImgContainer {
            margin: 0 auto;
            width: auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            max-width: 80%;
            .iconBoardBox{
                position: relative;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                .icontext{
                    display: flex;
                    gap: 10px;
                    padding: 0 20px;
                    background: #040805;
                    z-index: 5;
                    .iconSvg{
                        width: 50px;
                    }
                    .iconLabel{
                        color: green;
                        font-size: 20px;
                        margin: 0;
                    }
                }
                .iconborder{
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    position: absolute;
                    bottom: 30%;
                    z-index: 1;
                }
            }
            .modelProfileBox {
              padding-top: 20px;
              display: flex;
              width: auto;
              overflow-x: auto;
              justify-content: flex-start;
              max-width: 100%;
                &::-webkit-scrollbar {
                    display: none;
                }
                .indivModel {
                  width: 15vw;
                  margin-right: 15px;
                  padding: 15px;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  flex-shrink: 0;            
                    .indivsinglebox{
                        position: relative;
                        margin-bottom: 10px;
                        cursor: pointer;
                        .imgIndvi {
                            width: 100%;
                            height: 25rem;
                            object-fit: cover;
                            border-radius: 10px;
                        }
                        .modelTextDetail{
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            padding: 20px;
                            .mdlName{
                                color: #fff;
                                font-size: 36px;
                                font-weight: 200;
                                text-align: center;
                                line-height: 100%;
                                margin-bottom: 5px;
                            }
                            .mdlLabel{
                                color: #fff;
                                font-size: 18px;
                                font-weight: 200;
                                text-align: center;
                                margin-bottom: 10px;
                            }
                            .mdlOption{
                                color: #fff;
                                font-size: 22px;
                                font-weight: 200;
                                text-align: center;
                                margin: 0;
                                span{
                                    color: #fdb03c;
                                }
                            }
                        }
                    }
                    .statusSet{
                        color: #fff;
                        line-height: 100%;
                        margin: 0;
                        font-size: 22px;
                        width: 51%;
                    }
                    .statusLabel {
                      font-size: 18px;
                      text-align: center;
                      color: #fdb03c;
                      padding: 0 0 10px 0;
                      margin: 0;
                    }
                    .statusTextDropDown{
                      background: transparent;
                      button {
                        margin: auto;
                        display: block;
                        min-width: 85px;
                        text-transform: capitalize;
                        color: inherit !important;
                        background: transparent;
                        font-size: 16px;
                        box-shadow: none;
                        }
                        &.busyColor {
                          color: #FDB03C;
                        }
                        &.confirmedStatusColor {
                          color: skyblue;
                        }
                        &.noAnswerColor {
                          color: red;
                        }
                      }
                      .statusTextDesign {
                        font-size: 22px;
                        text-align: center;
                        font-weight: 500;
                        margin: 0;
                      }
                }
                .modelIndivBox {
                  width: 13vw;
                  position: relative;
                  flex-shrink: 0;
                  cursor: pointer;
                  .SinglModeImg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                  }
                  .modelSing {
                    position: absolute;
                    bottom: 0;
                    padding: 10px 20px;
                    .modleName {
                      font-size: 36px;
                      color: #fff;
                      font-weight: 200;
                      text-align: center;
                      line-height: 120%;
                      margin-bottom: 40px;
                    }
                    .modlelabel {
                      color: #fff;
                      font-size: 17px;
                      font-weight: 200;
                      text-align: center;
                      margin-top: -20px;
                    }
                    .modleOptionNo {
                      color: #fff;
                      font-size: 21px;
                      text-align: center;
                      margin: 0;
                      span {
                        color: #d49535;
                      }
                    }
                  }
                  .ThirdNocastbox {
                    border: 2px solid #fff;
                    width: 100%;
                    height: 46vh;
                    border-radius: 15px;
                    padding: 20px 20px 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    .modleNoCast {
                      font-size: 17px;
                      color: #fff;
                      font-weight: 200;
                      margin: 0;
                      padding-top: 30px;
                    }
                    .PersonPlusSvg {
                      width: 52px;
                      path {
                        fill: #fff;
                      }
                    }
                    .modleBtmSecNocast {
                      color: #fff;
                      text-align: center;
                      font-weight: 200;
                      .modleOptionNo {
                        color: #fff;
                        font-size: 21px;
                        text-align: center;
                        margin: 0;
                        span {
                          color: #d49535;
                        }
                      }
                    }
                  }
                  a {
                    text-decoration: none;
                  }
                }
            }
        }
    }
}
.projectDetailPage{
    width: 80%;
    margin: 6rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap:40px;
    .projectDetail{
        width: 30%;        
        .icontextTitle{
            display: flex;
            align-items: center;
            gap: 20px;
            .yellowcircle{
                width: 15px;
            }
            .proDetailLabel{
                color: #fdb03c;
                font-size: 36px;
                margin: 0;
                line-height: 100%;
                font-weight: 200;
            }
        }
        .valuewrappro{
          display: flex;
          align-items: center;
          .valuePro{ 
            text-align: center;
            margin-top: 20px;    
            padding: 0px;   
          }
          .valuePro:nth-child(1) {
            border: 1px solid #fff;
            border-radius: 10px;
            padding: 0px 45px;    
            margin-right: 40px;
          }
        }
        .valuePro{
            color: #ffffff;
            font-size: 18px;
            padding: 10px 0 20px 40px;
        }
    }
    .contactDetails{
        width: 60%;
        .icontextTitle{
            display: flex;
            align-items: center;
            gap: 20px;
            padding-bottom: 40px;
            .yellowcircle{
                width: 15px;
            }
            .proDetailLabel{
                color: #fdb03c;
                font-size: 36px;
                margin: 0;
                line-height: 100%;
                font-weight: 200;
            }
        }
        .contactDetail{
            display: flex;
            justify-content: space-between;
            color: #ffffff;
            padding-left: 40px;
            .mobileDigitlabel{
                width: 30%;
            }
            .mobileDigit{
                width: 70%;
            }
        }
    }
    .proStartEndDate{
        width: 35%;
        .icontextTitle{
            display: flex;
            align-items: center;
            gap: 20px;
            padding-bottom: 30px;
            .yellowcircle{
                width: 15px;
            }
            .proDetailLabel{
                color: #fdb03c;
                font-size: 36px;
                margin: 0;
                line-height: 100%;
                font-weight: 200;
            }
        }
        .innerDateSec{
            display: flex;
            gap: 10px;
            padding-left: 40px;
            padding-bottom: 60PX;
            .dateBoxone{
                width: 50%;
                color: #fff;;
                text-align: center;

                .selectbox{
                    width: 100%;
                    color: #000;
                    background-color: #fff;
                    border-radius: 10px;
                }
            }
            .dateBoxtwo{
                width: 25%;
                @extend.dateBoxone;
            }
            .dateBoxthree{
                width: 25%;
                @extend.dateBoxone;
            }
        }
    }
}

@media screen and (max-width:1600px) {
  .mainHeader {
    margin-top: 30px;
    .logoBox{
      margin-left: 14px;
      gap: 26px;
      .logoImg{
        width: 60px;
      }
      .logotext{
        .logobottomText{
          font-size: 22px;
          line-height: 1.3;
          margin-bottom: 10px;
        }
        .logotopText{
          margin-bottom: 0px;
          font-size: 32px;
        }
      }
    }
    .toptext{
      font-size: 18px;
    }
    .detailBreakDwn{
      .selectedProBtn{
        font-size: 16px;
        padding: 6px 30px;
        width: 20%;
      }
      .breakBtn{
        font-size: 16px;
        padding: 6px 30px;
        width: 20%;
      }
      .proBtn{
        font-size: 16px;
        padding: 6px 30px;
        width: 20%;
      }
      .selectedBreakBtn{
        font-size: 16px;
        padding: 6px 30px;
        width: 20%; 
      }
    }
    .detailBreakDwn{
      margin-left: 14px;
      padding-top: 15px;
    }
  }
  .projectDetailPage{
    padding: 30px 14px;
    gap: 20px;
    width: 100%;
    margin: 0;
    .projectDetail{
      width: 32%;
      .icontextTitle{
        gap:15px;
        .yellowcircle{
          width: 12px;
        }
        .proDetailLabel{
          font-size: 22px;
        }
      }
      .valuePro{
        font-size: 16px;
        padding: 10px 0 20px 27px;
      }
      }
      .proStartEndDate{
        width: 40%;
        .icontextTitle{
          padding-bottom: 20px;
          gap:15px;
          .proDetailLabel{
            font-size: 22px;
          }
          .yellowcircle{
            width: 12px;
          }
        }
        .innerDateSec{
          padding-left: 27px;
          padding-bottom: 25px;
          .dateBoxtwo{
            p{
              margin-bottom: 10px;
            }
          }
          .dateBoxone{
            p{
            margin-bottom: 10px;
          }
        }
          .dateBoxthree{ 
            p{
              margin-bottom: 10px;
            }
          }
        }
    }
    .contactDetails{
      width: 58%;
      .icontextTitle{
        gap:15px;
        padding-bottom: 20px;
        .yellowcircle{
          width: 12px;
        }
        .proDetailLabel{
          font-size: 22px;
        }
      }
      .contactDetail{
        padding-left: 27px;
        .mobileDigitlabel{
          font-size: 16px;
          margin-bottom: 10px;
        }
        .mobileDigit{
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .breakDownPage {
    .leftSideDesign{
      margin-left: 14px;
      .roleTotalNum{
        font-size: 18px;
        padding: 35px 0 15px;
      }
      .rolesList{
        .innerRolesList{
          .listIcontext{
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .selectedListIcontext{
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .rightSideDesign{
      .castBrkBox{
      margin: 0 6% 48px;
      .castTitle{
        font-size: 24px;
      }
      .detailNumbBox{
        .detailtxt{
          font-size: 18px;
        }
      }
    }
    .iconTileImgContainer{
      .iconBoardBox{
        .icontext{
          align-items: center;
          .iconLabel{
            font-size: 16px;
          }
          .iconSvg{
            width: 30px;
          }
        }
      }
      .modelProfileBox {
        .indivModel{
          width: 250px;
          margin: 0;
          .imgIndvi{
            height: 350px!important;
          }
          .indivsinglebox {
            .modelTextDetail {
            .mdlName{
              font-size: 24px;
              margin-bottom: 15px;
                }
                .mdlLabel{
                  font-size: 16px;
                  margin-bottom: 0;
                }
                .mdlOption{
                  font-size: 18px;
                  margin-top: 10px;
                }
              }
            }
        }
      }
    }
  }
  }
}