.modalMainContainer {
    position: absolute;
    float: left;
    left: 30%;
    top: 20%;
    min-width: 700px;
    min-height: 500px;
}

.videoPlayer{
    display: block;
    margin: auto;
    width: 100%;
}