.profileTwoPageAttach {
  scrollbar-width: none; /* For Firefox 64  Scroll Bar*/
}
.modelImgSecClass {
  scrollbar-width: none; /* For Firefox 64  Scroll Bar*/
}
.DetailAreaBox {
  scrollbar-width: none; /* For Firefox 64  Scroll Bar*/
}
.profileTwoPageAttach{
  width: calc(100% - 85px);
  margin-left: 85px;
  padding: 80px 0px 10px ;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  height: calc(100vh - 120px);
  &::-webkit-scrollbar{
    display: none;
  }
  .modelsProfilePage{
    display: flex;
    width: 100%;
    flex-shrink: 0;
    gap: 40px;
    scroll-snap-align: start;
    .modelImgSec {
      flex-shrink: 0;
      width:34%;
      position: relative;
      display: flex;
      justify-content: center;
      .modelImgSecClass {
        border-radius: 33px;
        border: 1px solid #fff;
        overflow: hidden;
        width: 80%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
          cursor: pointer; 
          object-fit: cover;
        }
      }
      .rightarrClass{
        position: absolute;
        right: 10px;
        top: 50%;
        width: 35px;
        cursor: pointer;
        path{
          fill: #fff;
        }
        &:hover{
          path{
            fill: #fdb03c;
          }
        }
      }
      .leftarrClass{
        position: absolute;
        left: 10px;
        top: 50%;
        width: 35px;
        cursor: pointer;
        path{
          fill: #fff;
        }
        &:hover{
          path{
            fill: #fdb03c;
          }
        }
      }
    }
    .modelsVideoSec {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-end;
      gap: 40px;
      width: calc(66% - 40px);
      padding-right: 40px;
      .modelVidLogo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .ModelLogoIcon {
          width: 50%;
          display: flex;
          gap: 20px;
          .sideImgbox {
            text-align: center;
            cursor: pointer;
            .favIcon {
              width: 65px;
              g {
                path{
                  fill: red;
                }
              }
            }
            .nonFavIcon {
              width: 65px;
            }
            .sideimgsvgtxt {
              font-size: 20px;
              color: #fff;
            }
          }
          .sideBarlogoTxt {
            .sidelgtxt {
              font-size: 42px;
              font-weight: 200;
              color: #fff;
              line-height: 42px;
            }
            .sidelgsubtxt {
              font-size: 42px;
              font-weight: 200;
              line-height: 42px;
              margin: 0;
              color: #fdb03c;
            }
          }
        }
        .modelIconBtn {    
          width: auto;
          .modlBtn {
            font-size: 20px;
            color: #fff;
            line-height: 100%;
            font-weight: 200;
            padding: 5px 40px;
            border: 1px solid #fff;
            background-color: transparent;
            border-radius: 20px;
            margin-top: 15px;
            &:hover{
              color: #FFF;
              border: 1px solid #FFF;
              background: #FDB03C;
            }
          }
        }
      }
      .modelSelectBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap:40px;
        .filterLabel{
          width:30%;
          font-size: 40px;
          line-height: 100%;
          color: #fdb03c;
          font-weight: 200;
          text-align: end;
        }
        .filterBox{
          width: 40%;
          .colFormcalss{
            margin: 0;
          }
        }
      }
      .ModelVideoSec {
        width: 100%;
        display: flex;
        gap:53px;
        .ModelAgeName {
          width: 20%;

          .AgrNameTxt {
            font-size: 45px;
            font-weight: 200;
            color: #fff;
            span{
              color: #fdb03c;
              width: 100%;
              display: block;
            }
          }
          .MdlLabel {
            color: #fff;
            font-size: 26px;
            font-weight: 200;
            margin: 0;
          }
        }
        .modelVideo {
          width: 100%;
          position: relative;
          border-radius: 33px;
          overflow: hidden;
          .reactPlayer {
            width: 900px !important;
            height: 500px !important;
          }
          .modelvideoClass {
            height: 55vh;
            width: 100%;
            object-fit: cover;
          }
          .Playbtn {
            width: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
          }
        }
      }
    }
  }
  .DetailAreaBox::-webkit-scrollbar{
    display: none;
  }
  .DetailAreaBox{
    padding:0 40px 0 75px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    scroll-snap-align: start;
    overflow: auto;
    .detailWrap{
      display: inline-table;
    }
    .detailAreaDivFix{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 60px;
      .modelVidLogo{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .ModelLogoIcon{
          width: 50%;
          display: flex;
          gap: 20px;
          .sideImgbox{
            text-align: center;
            cursor: pointer;
            .favIcon {
              width: 65px;
              g {
                path{
                  fill: red;
                }
              }
            }
            .nonFavIcon{
              width: 65px;
            }
            .sideimgsvgtxt{
              font-size: 20px;
              color: #fff;
            }
          }
          .sideBarlogoTxt{
            .sidelgtxt{
              font-size: 42px;
              font-weight: 200;
              color: #fff;
              line-height: 42px;
            }
            .sidelgsubtxt{
              font-size: 42px;
              font-weight: 200;
              line-height: 42px;
              margin: 0;
              color: #fdb03c;
            }
          }
        }
        .modelIconBtn{    
          width: auto;
          .modlBtn{
            font-size: 20px;
            color: #fff;
            line-height: 100%;
            font-weight: 200;
            padding: 5px 40px;
            border: 1px solid #fff;
            background-color: transparent;
            border-radius: 20px;
            margin-top: 15px;
            &:hover{
              color: #FFF;
              border: 1px solid #FFF;
              background: #FDB03C;
            }
          }
        }
      }
      .modelTalentDesc{
        width: 35%;
        .talentSolaTitle{
          font-size: 40px;
          color: #fdb03c;
          padding-bottom: 50px;
        }
        .modelTalentBox{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .talentSecBox{
            width: 50%;
            display: flex;
            align-items: flex-start;
            gap: 20px;
            .detaiListIcon{
              width: 16px;
              padding-top: 4px;
            }
            .detailTexModel{
              font-size: 24px;
              font-weight: 400;
              color: #fff;
              line-height: 100%;
              .DetailLabelHead{
                color: #fdb03c;
              }
              .DetailOfHead{
                list-style: none;
                padding-bottom: 50px;
                .detailTalentList{
                  padding-bottom: 10px;
                }
              }
            }
          }
        }
      }
      .ModelsAllDetail {
        width: calc(65% - 65px);
        display: flex;
        flex-direction: column;
        .physDetailTitle {
          width: 100%;
          font-size: 40px;
          color: #fdb03c;
          padding-bottom: 50px;
        }
        .physDetailBox {
          display: flex;
          flex-wrap: wrap;
          .mdlDetailRgtSD {
            width: 25%;
            display: flex;
            align-items: flex-start;
            gap: 18px;
            padding-bottom: 20px;
            .detaiListIcon {
              width: 16px;
              padding-top: 4px;
            }
            .detailTexModel {
              font-size: 24px;
              font-weight: 200;
              color: #fff;
              line-height: 100%;
              .DetailLabelHead {
                color: #fdb03c;
              }
            }
          }
        }
      }
    }
  }
}

.leftRightHands {
	width: calc(100% - 100px);
	margin-left: 85px;
	display: flex;
	justify-content: center;
	position: relative;
  position: fixed;
    bottom: 0;
    background: #090f12;
	cursor: pointer;
	.leftHandArrow {
		position: absolute;
		left: 85px;
		top: 30px;
		display: flex;
		align-items: center;
		gap: 22px;
		margin-left: 0px;
		&:hover {
			.lefthandtext {
				color: #FDB03C;
			}
			.lefthanfSvg {
				path {
					fill: #FDB03C;
				}
			}
		}
		.lefthandtext {
			font-size: 16px;
			font-weight: 200;
			line-height: 20px;
			color: #fff;
			margin: 0;
		}
		.lefthanfSvg {
			width: 32px;
		}
	}
	.rightHandarrow {
		position: absolute;
		right: 0;
		top: 30px;
		padding-right: 85px;
		display: flex;
		align-items: center;
		gap: 22px;
		&:hover {
			.Righthandtext {
				color: #FDB03C;
			}
			.lefthanfSvg {
				path {
					fill: #fdb03c;
				}
			}
		}
		.Righthandtext {
			color: #FFF;
			font-size: 16px;
			font-weight: 200;
			line-height: 20px;
			margin: 0;
		}
		.lefthanfSvg {
			width: 32px;
			path {
				fill: #ffffff;
			}
		}
	}
}

.rightDisable {
  @extend .rightHandarrow;
  visibility: hidden;
}

.leftDisable {
  @extend .leftHandArrow;
  visibility: hidden;
}

@media screen and (max-width:1600px) {
  .profileTwoPageAttach{
    height: auto;
    padding: 0px;
    .modelsProfilePage{
      gap: 20px;
      padding: 30px 0 95px 0;
      .modelImgSec {
        height: calc(100vh - 153px);
        .modelImgSecClass {
          width: 75%;
        }
        .rightarrClass{
          width: 28px;
        }
        .leftarrClass{
          width: 28px;
        }
      }
      .modelsVideoSec {
        align-content: baseline;
        gap: 30px;
        .modelVidLogo{ 
          .ModelLogoIcon {
            justify-content: space-between;
            .sideBarlogoTxt{
              .sidelgtxt{
                font-size: 20px;
                line-height: 1.3;
              }
              .sidelgsubtxt{
                font-size: 30px;
                line-height: 1.3;
              }
            }
            .sideImgbox {
              .favIcon {
                width: 40px;
              }
              .nonFavIcon {
                width: 40px;
              }
              .sideimgsvgtxt{
                font-size: 16px;
                margin: 0;
              }
            }
          }
          .modelIconBtn {    
            .modlBtn {
              font-size: 18px;
              padding: 5px 30px;
            }
          }
        }
        .ModelVideoSec{
          .ModelAgeName{
            width: 40%;
            .AgrNameTxt{
              font-size: 30px;
            }
            .MdlLabel{
              font-size: 18px;
            }
          }
          .modelVideo{
            width: 75%;
            .reactPlayer {
              width: 465px !important;
              height: 280px !important;
            }
            .modelvideoClass{
              height: 100%;
              min-height: 320px;
              max-height: 370px;
            }
          }
        }
        .modelSelectBox {
          gap: 20px;
          .filterLabel{
            width: 40%;
            font-size: 26px;
          }
        }
      }
    }
    .DetailAreaBox{
      .detailAreaDivFix{
        gap: 20px;
        .modelVidLogo{ 
          .ModelLogoIcon {
            align-items: center;
            .sideBarlogoTxt{
              .sidelgtxt{
                font-size: 20px;
                line-height: 1.3;
              }
              .sidelgsubtxt{
                font-size: 30px;
                line-height: 1.3;
              }
            }
            .sideImgbox {
              .favIcon {
                width: 40px;
              }
              .nonFavIcon {
                width: 40px;
              }
              .sideimgsvgtxt{
                font-size: 16px;
                margin: 0;
              }
            }
          }
          .modelIconBtn {
            .modlBtn{
              font-size: 18px;
              padding: 5px 30px;
            }
          }
        }
        .modelTalentDesc {
          width: 30%;
          .talentSolaTitle{
              font-size: 30px;
              padding-bottom: 10px;
          }
          .modelTalentBox{
            .talentSecBox{
                gap: 15px;
                .detaiListIcon{
                  width: 12px;
                }
                .detailTexModel{
                  .DetailOfHead {
                    .detailTalentList{
                      font-size: 16px;
                      padding-bottom: 5px;
                    }
                  }
                  .DetailLabelHead{
                    font-size: 22px;
                    margin-bottom: 10px;
                  }
                } 
            }
          }
        }
        .ModelsAllDetail {
          width: calc(70% - 65px);
          .physDetailTitle{
            font-size: 30px;
            padding-bottom: 10px;
          }
          .physDetailBox{
            .mdlDetailRgtSD{
              gap:15px;
              padding: 10px 10px;
              flex-wrap: wrap;
              .detaiListIcon{
                width: 12px;
              }
              .detailTexModel{ 
                width: calc(100% - 27px);
                .DetailLabelHead{
                  font-size: 22px;
                }
                .DetailOfHead{
                  font-size: 16px;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .leftRightHands{
		.rightHandarrow{
			padding-right: 50px;
			gap: 15px;
      .Righthandtext{
        font-size: 14px;
      }
		}
    .leftHandArrow{
      left: 50px;
      gap: 15px;
      .lefthandtext{
        font-size: 14px;
      }
    }
	}
  .profileTwoPageAttach{
    .DetailAreaBox{
      padding: 30px 30px 93px 30px;
    height: 100vh;
    overflow-y: scroll;
    }
  }
}
