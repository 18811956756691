.footerContent {
  display: flex;
  justify-content: center;
  gap: 55px;
  padding:20px 0;
  .footImgbox {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    z-index: 9;
    &:hover{
      filter: brightness(0.4);
    }
    .linkLogo {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
      .footIcontxt {
        padding-top: 13px;
      }
      .SelectedProject {
        width: 50px;
        margin-top: -10px;
        margin-bottom: -10px;
      }
    }
    .footIcontxt {
      font-weight: 200;
      font-size: 12px;
      line-height: 15px;
      color:#fff;
      padding-top: 5px;
      margin: 0;  
    }
  }

  .CameraLog {
    margin-top: 5px;
  }
}


@media screen and (max-width:1600px) {
  .footerContent {
        gap: 30px;
        padding: 10px 0;
        padding: 14px;
    .footImgbox {
      .linkLogo {
        img{
          max-height: 25px;
        }
        svg{
          max-height: 25px;
        }
        .SelectedProject {
          width: auto;
          max-height: 45px;
        }
      }
      svg{
        max-height: 25px;
      }
    }
  }
}