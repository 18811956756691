.contentWrapper{
    .contentRow{
        .userDlatacol{
            .deleteAll{
                margin-right: 20px;
                button{
                background-color: #d67013;
                font-size: 1em;
                font-weight: 600;
                color: #ffffff;
                padding: 3px 5px;
                border: none !important;
                // outline: none;
            }
               
            }            
        }

        .acoountHead{
            background-color: #0e1518;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            color: white;
            margin-top: 50px;
            .actionCol{
                display: flex;
                justify-content: center;
                h2{
                    margin: 0;
                    line-height: 1;
                }
            }

         }
    }
}



// css for search btn

.btnsCol{
    background-color: #d67013;
    border-radius: 50px;
               
    display: flex;
    justify-content: center;
    .defaultDropdown{
        button{
            box-shadow: none!important;
            outline: none!important;
          
            outline: none!important;
            border: none!important;
                            background-color: transparent!important;
        color: white;
        border-right: 1px solid white!important;
        border-radius: 0;
    
        }
    }
    .userSearch{
        .searchBar{
            border-bottom-right-radius: 50px;
            border-top-right-radius: 50px;
            margin: 0;
            input{
            
            box-shadow: none!important;
            outline: none!important;
          
            outline: none!important;
            border: none!important;
            border-bottom-right-radius: 50px;
            border-top-right-radius: 50px;
            }
        }
    }
}