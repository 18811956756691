.login-wrraper {
  background-color: red;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  .loginCol{
    width:calc(100% / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    .formCol {
      width: 100%;
      .signText{
        font-size: 50px;
        color: rgb(255, 255, 255);
        text-align: center;
        margin-bottom: 1em;
      }
    }
  }
}
.loginDirector {
  @extend .login-wrraper;
  background-image: url('https://wallpaperaccess.com/full/427852.jpg');
}
.helperRow{
    display: flex;
    color: white;
    justify-content: space-between;
    margin-bottom: 1em;
    .forgotText{
        font-size: 16px;
        cursor: pointer;
    }
}
.loginForm{
    width: 100%;
    max-width: 100%;
}
.rememberCheck .form-check{
    display: flex;   
}
.loginBtn {
    display: flex;
    text-decoration: none;
    font-size: 18px;
    button{
       background-image:linear-gradient(1720deg, rgba(117, 131, 134, 0.99608),  rgb(250,193,26));
       border: none!important;
       width: 160px;
       padding: 0.4em 0;
       border-radius: 50px;
       margin: auto;
       text-align: center;
       box-shadow: none!important; 
       font-size: 18px;
       font-weight: 600;   
    }       
}
.login-director{
  width: 100%;
}
//  Left side login section
.leftLoginSection {
  background-color: #fff;
  width: 35%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 0;
  padding-top: 25px;
  overflow: hidden;
  .formCol{
    width: 100%;
    padding: 0 50px;
  }
  .signText{
    font-size: 2rem;
    font-weight: 200;
    padding-bottom: 30px;
  }
  .login-form {
    width: 100%;
    text-align: center;
  }
  .forgotText, .rememberCheck{
    color: #000 !important;
    font-size: 1rem;
  }
  .dirLoginBtm {
    background-color: #000 ;
    opacity: 1 !important;
    font-size: 1.6rem;
    border-radius: 8px;
    line-height: .9;
    border: none;
    font-weight: 200;
    padding: 0 50px 7px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border: 1px solid #000;
    cursor: pointer;
    &:hover{
      background:  #fdb03c;
      color: #000;
    }
  }
  .passwrdGrpFld{
    background-color: #e9a237;
    color: #000;
    border-radius: 8px;
    .input-group {
      border-bottom: 0;
      img{
        width: 22px;
      }      
      input{
        text-align: center;
        font-size: 1.5rem;
        line-height: 100%;
        padding: 0 10px 6px 0;    
        &::placeholder{
          color: #000;
        }
      }
    }
  }
  .emailGrpFld{
    background-color: #000;
    border-radius: 8px;
    color: #fff;
    .input-group {
      border-bottom: 0;
      .input-group-text{
        padding-right: 0 !important
      }
      img{
        width: 22px;
      }      
      input{
        text-align: center;
        font-size: 1.5rem;
        line-height: 100%;
        padding: 0 10px 6px 0;
        background-color: inherit !important;
        &::placeholder{
          color: #fff;
        }
      }
    }
  }
  .passwrdGrpFld input,
  .emailGrpFld input {
    background-color: inherit;
    color: inherit;
    text-align: center;
    border: none;
    padding-left: 0;
  }
  .passwrdGrpFld input::placeholder,
  .emailGrpFld input::placeholder {
    color: inherit;
  } 
  .formTopLogo{
    width: 100%;
    padding: 0 50px;
    text-align: center;
    .logoPic{
      width: 80px;
    }
    p{
      font-size: 1.75rem;
      font-weight: 200;
      color: #000;
    }
  }
  .dirLogBottomSec{
    width: 100%;
    text-align: center;
    position: relative;
    img{
      opacity: 0.25;
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 0;
      left: 0;
    }
    .dirBotBox{  
      padding: 50px 0;    
      label{
        font-size: 1.65rem;
        font-weight: 200;
        margin: 0;
      }
      h4 {
        font-size: 1.8rem;
      }
    }
  }
}
// Right Side page  
.sideRhtImg{
  width: 65%;
  height: 100vh;
  overflow: hidden;
  background-color: #0a1012;
  font-size: 16px;
  color: #fff;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  position: relative;
  &::before{
    content: '';
    background-image: url(../../assets/images/bg-cam.png);
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }
  div{
    position: relative;
    z-index: 1;
    h3{
      font-size: 2.25rem;
      font-weight: 200;
      span{
        color: #fdb03c;
      } 
    }
  }
  .titleHead{
    width: 100%;
    h2{
      font-size: 3.5rem;
      padding: 50px 10% 0;
      font-weight: 200;
      span{
        color: #fdb03c;
      }
    }
  }
  .fieldText{
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5%;
    gap: 50px;
    .fldMainBox{
      width: 30%;
      label{
        font-size: .98rem;
        color: #fdb03c;      
      }
      p{
        margin-left: 30px;
        background-color: #fff;
        color: #000;
        text-align: center;
        padding-right: 20px;
        font-weight: 400;
        font-size: 1rem;
        border-radius: 20px;
        position: relative;
        &::after{
          content: ' \25BE';
          color: #000;
          position: absolute;
          right: 15px;
        }
      }
      aside{
        height: 5px;
        background-color: #fff;
        margin-left: 30px;
        border-radius: 10px;
        position: relative;
        &::after{
          content: '25';
          color: #000;
          background-color: #fff;
          width: 25px;
          height: 25px;
          font-size: .85rem;
          font-weight: 500;
          border-radius: 50%;
          position: absolute;
          left: 15%;
          top: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &::before{
          content: '30';
          color: #000;
          width: 25px;
          height: 25px;
          background-color: #fff;
          font-size: .85rem;
          font-weight: 500;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .bottomImgText {
    width: 100%;
    padding: 0 8% 50px 12%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    h3 {
      width: 50%;
    }
    img{
      width: 45%;
    }
  }
  .bgFreeImg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-position: right;
    object-fit: contain;
  }
}

@media screen and (max-width: 1600px) {
  .sideRhtImg {
    padding-bottom: 40px;
    gap: 40px;
    width: 70%;
    .titleHead{
      h2{
        padding-top: 40px;
        font-size: 3rem;
      }
    }
    div {
      h3 {
        font-size: 2rem;
      }
    }
  }
  .leftLoginSection {
    width: 30%;
      .formTopLogo {
        p{
          font-size: 1.5rem;
        }
        .logoPic {
          width: 65px !important;
        }
      }
      .formCol{
        padding: 0 30px;
        h5{
          padding-bottom: 12px;
          font-size: 1.65rem;
        }
      }
      .forgotText, .rememberCheck {
        font-size: .9rem;
      }
      .dirLoginBtm {
        font-size: 1.6rem;
        padding: 0 40px 7px;
      }
      .helperRow{
        margin-bottom: .5rem;
      }
      .dirLogBottomSec {
        .dirBotBox {
          padding: 40px 0;
        }
        .dirBotBox {
          label{
            font-size: 1.5rem;
          }
          h4 {
            font-size: 1.65rem;
          }
        }
      }
    }
  
}

@media screen and (max-width: 767px) {
  .leftLoginSection {
    width: 100%;
  }
  .sideRhtImg {
    display: none;
  }
}
