.searchBar{
    background-color: white;
    border-radius: 4px;
    .input-group-prepend{
        padding: 5px;
        background-color: transparent;
        border-right: 0;
        width: 25px;
        img{
            width: 22px;
            height: auto;
        }
    }
}