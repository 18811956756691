.content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  .radioBtn {
    transform: scale(1.5);
    margin-top: 8px;
    accent-color: #d67013;
    cursor: pointer;
  }
  .episodeName {
    margin-top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}

.submitBtn {
  margin-top: 20px;
}